import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, useTheme, Box } from "@mui/material";
import { tokens } from "../../theme";
import Avatar from '@mui/material/Avatar';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';


export default function ProfileCard() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const userState = useSelector((state) => state.userData);
    const { user } = userState;
    const small = useMediaQuery("(max-width: 600px)");
    const size = small ? 190 : 230;

    return (
        <Card sx={{ marginRight: "20px", backgroundColor: colors.primary[400]}}>      
        <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                    sx={{ 
                        bgcolor: colors.blueAccent[500],
                        cursor: "pointer", 
                        borderRadius: "50%",
                        fontSize: "120px",
                        width: size, height: size
                    }}
                >{user ? user.name[0].toUpperCase() : ""}</Avatar>                
            </Box>
            <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                    {user?.name}
                </Typography>
                <Typography 
                  variant="h5" 
                  sx={{ m: "10px 0 0 0" }}
                  color={colors.greenAccent[500]}>
                  {user?.email}
                </Typography>
            </Box>              
        </CardContent>
        </Card>
    );
}