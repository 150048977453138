import {
    ADD_EMPLOYER_REQUEST,
    ADD_EMPLOYER_SUCCESS,
    ADD_EMPLOYER_FAIL,
    UPDATE_EMPLOYER_REQUEST,
    UPDATE_EMPLOYER_SUCCESS,
    UPDATE_EMPLOYER_FAIL,
    DELETE_EMPLOYER_FAIL,
    DELETE_EMPLOYER_SUCCESS,
    GET_EMPLOYER_REQUEST,
    GET_EMPLOYER_SUCCESS,
    GET_EMPLOYER_FAIL,
    RESET_EMPLOYER,
    EMPLOYER_DASHBOARD_REQUEST,
    EMPLOYER_DASHBOARD_SUCCESS,
    EMPLOYER_DASHBOARD_FAIL,
    FETCH_EMPLOYERS_REQUEST,
    FETCH_EMPLOYERS_SUCCESS,
    UPDATE_WEEKLYEMAILNOTIFICATION_REQUEST,
    UPDATE_WEEKLYEMAILNOTIFICATION_SUCCESS,
    UPDATE_WEEKLYEMAILNOTIFICATION_FAIL
  } from "../Constants/employerConstants";
  
  const initialState = {
    employerAdded: false,
    employerUpdated: false,
    employerDeleted: false,
    dashboardData: {
      "employeeCount": 0,
      "jobCount": 0,
    },
    weeklyEmailNotificationUpdated: false,
    employers: [],
    employer: null,
    loading: false,
    error: null
  };
  
  export const employerReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_EMPLOYER_REQUEST:
        return { ...state, loading: true };
      case ADD_EMPLOYER_SUCCESS:
        return { ...state, loading: false, employerAdded: action.payload };
      case ADD_EMPLOYER_FAIL:
        return { ...state, loading: false, error: action.payload };
      case UPDATE_EMPLOYER_REQUEST:
        return { ...state, loading: true };
      case UPDATE_EMPLOYER_SUCCESS:
        return { ...state, loading: false, employerUpdated: action.payload };
      case UPDATE_EMPLOYER_FAIL:
        return { ...state, loading: false, error: action.payload };
      case DELETE_EMPLOYER_SUCCESS:
        return { ...state, loading: false, employerDeleted: action.payload };
      case DELETE_EMPLOYER_FAIL:
        return { ...state, loading: false, error: action.payload };
      case GET_EMPLOYER_REQUEST:
        return { ...state, loading: true };
      case GET_EMPLOYER_SUCCESS:
        return { ...state, loading: false, employer: action.payload };
      case GET_EMPLOYER_FAIL:
        return { ...state, loading: false, error: action.payload };
      case EMPLOYER_DASHBOARD_REQUEST:
        return { ...state, loading: true };
      case EMPLOYER_DASHBOARD_SUCCESS:
        return { ...state, loading: false, dashboardData: action.payload };
      case EMPLOYER_DASHBOARD_FAIL:
        return { ...state, loading: false, error: action.payload };
      case FETCH_EMPLOYERS_REQUEST:
        return { ...state, loading: true };
      case FETCH_EMPLOYERS_SUCCESS:
        return { ...state, loading: false, employers: action.payload };
      case UPDATE_WEEKLYEMAILNOTIFICATION_REQUEST:
        return { ...state, loading: true };
      case UPDATE_WEEKLYEMAILNOTIFICATION_SUCCESS:
        return { ...state, loading: false, weeklyEmailNotificationUpdated: true };
      case UPDATE_WEEKLYEMAILNOTIFICATION_FAIL:
        return { ...state, loading: false, error: action.payload };
      case RESET_EMPLOYER:
        return initialState;      
      default:
        return state;
    }
  };