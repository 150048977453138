import AdminSidebar from "../AdminSideBar/AdminSideBar";
import EmployerSideBar from "../EmployerSideBar/EmployerSideBar";
import { Box } from "@mui/material";
import TopBar from "../TopBar/TopBar";
import Footer from "../Footer/Footer";
import { useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from "react";

function Layout(props) {
    const small = useMediaQuery("(max-width: 600px)");
    const isMedium = useMediaQuery("(max-width: 960px)");

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [showSidebar, setShowSidebar] = useState(isMedium ? "none" : "block");

    const handleCollapsed = (val) => {
        setIsCollapsed(val);
        if(isMedium) {
            setShowSidebar("none");            
        }
    }

    useEffect(()=>{
        setIsCollapsed(false);
        setShowSidebar(isMedium ? "none" : "block");
    }, [small, isMedium]);

    return (
        <Box sx={{ height: "100vh" }}>
            <Box sx={{ display: "flex", height: "100vh" }}>
                { props?.type === "Employer" ?  
                    <EmployerSideBar 
                        isCollapsed={isCollapsed} 
                        handleCollapsed={handleCollapsed} 
                        showSidebar={showSidebar}
                    /> : 
                    <AdminSidebar 
                        isCollapsed={isCollapsed} 
                        handleCollapsed={handleCollapsed} 
                        showSidebar={showSidebar}
                    />
                }
                <main 
                    className="content" 
                    style={{ 
                        width:  "100%", 
                        marginLeft: isMedium ? "0px" : isCollapsed ? "80px" : "250px"
                    }}>
                    <TopBar handleSidebar={() => {
                        setShowSidebar("block");
                        setIsCollapsed(true);
                    }}/>
                    {props.children}            
                    <Box sx={{ height: "60px" }} />
                </main>
                <Footer />
            </Box>
        </Box>
    );
}

export default Layout;