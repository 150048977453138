import React from 'react';
import { Box, Typography } from "@mui/material";


export function TableLoading() {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Typography>Loading...</Typography>    
    </Box>
  );
};
