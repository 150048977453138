import React, {useEffect} from 'react';
import { Box, IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { tokens } from "../../theme";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../services/Actions/userActions";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isSubPage } from '../../Utils';

const TopBar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMedium = useMediaQuery("(max-width: 960px)");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userState = useSelector((state) => state.userData);
  const { user } = userState;

  useEffect(() => {
    if(user === null) {
        navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    setShowBackBtn(isSubPage(location.pathname));
  }, [location]);


  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
      >
        {isMedium && 
          <IconButton type="button" sx={{ p: 1, mr: 2 }} onClick={props.handleSidebar}>
            <MenuOpenIcon/>
          </IconButton>
        }
        {
          showBackBtn &&
          <Box
            backgroundColor={colors.primary[400]}
            borderRadius="5px"
          >
            <IconButton 
              type="button" 
              sx={{ p: 1 }} 
              onClick={() => { 
                const regex = /\/([^/]+)/;
                const result = `/${location.pathname.match(regex)[1]}s`;
                console.log("result", result);
                navigate(result);
              }}>
              <ArrowBackIcon/>
            </IconButton>
          </Box>
        }
      </Box>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >        
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={handleClick}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={ () => navigate("/profile")}>
          <ListItemIcon>
            <PersonOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={ () => dispatch(logoutUser()) }>
        <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TopBar;
