import React, {useEffect} from 'react';
import { Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import JobForm from "../../components/Job/JobForm";
import { useDispatch, useSelector } from "react-redux";
import { addJob } from "../../services/Actions/jobActions";
import { toast } from "react-toastify";
import { RESET_JOB } from '../../services/Constants/jobConstants';
import { useNavigate } from "react-router-dom";

const AddJob = () => {

  const initialValues = {
    jobName: "",
    jobDescription: "",
    lineManager: "",
    lineMangerEmail: "",
    longitude: "",
    latitude: "",
    employeeId: "",
    thresholdTime: "", 
    breakTime: "",
    offDays: [],
    shiftDetails: [{"shiftStartEndDate": [], "shiftStartEndTime": []}]
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userData);

  const jobState = useSelector((state) => state.jobData);
  const { jobAdded, error } = jobState;

  useEffect(() => {
      if(jobAdded) {
        toast.success("Job Added Successfully");
        dispatch({ type: RESET_JOB});
        navigate("/jobs");
      }
  }, [jobAdded, dispatch]);

  useEffect(() => {
      if(error) {
        toast.error(error.message);
        dispatch({ type: RESET_JOB})
      }
  }, [error, dispatch]);

  const handleFormSubmit = (job) => {

    dispatch(addJob({
      'employerId': userData.user.id,
      ...job
    }));
  };

  return (
    <Layout type="Employer">
        <Box>
            <Box m="20px">
                <PageHeader title="ADD JOB" subtitle="Add a new Job" />
                  <Box>
                      <JobForm initialValues={initialValues} handleFormSubmit={handleFormSubmit} btnText={"Add Job"} loading={false}/>
                  </Box>                    
            </Box>             
        </Box>      
    </Layout>
  );
};

export default AddJob;
