import API from "../../middleware/api";
import {
    ADMIN_DASHBOARD_REQUEST,
    ADMIN_DASHBOARD_SUCCESS,
    ADMIN_DASHBOARD_FAIL
} from "../Constants/adminConstants";

const api = new API();

export const getDashboardData = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DASHBOARD_REQUEST });
    const {data} = await api.get("/admin/count");


    dispatch({ type: ADMIN_DASHBOARD_SUCCESS, payload: data });
  } catch (error) {

    dispatch({
      type: ADMIN_DASHBOARD_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
}; 


