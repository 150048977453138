import {
    UPDATE_STATUS_REQUEST,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAIL,
    RESET_TIMELOGS,
    FETCH_TIMELOGS_REQUEST,
    FETCH_TIMELOGS_SUCCESS,
    FETCH_LOGS_REQUEST,
    FETCH_LOGS_SUCCESS,
    FETCH_LOGS_FAIL,
    UPDATE_LOGS_REQUEST,
    UPDATE_LOGS_SUCCESS,
    UPDATE_LOGS_FAIL,
} from "../Constants/timelogConstants";

  const initialState = {
    timelogs: [],
    logs: [],
    loading: false,
    updatedStatus: false,
    updatedLogs: false,
    error: null
  };
  
  export const timelogReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_STATUS_REQUEST:
        return { ...state, loading: true, updatedStatus: false};
      case UPDATE_STATUS_SUCCESS:
        return { ...state, loading: false, updatedStatus: true };
      case UPDATE_STATUS_FAIL:
        return { ...state, loading: false, updatedStatus: false, error: action.payload };
      case UPDATE_LOGS_REQUEST:
        return { ...state, loading: true, updatedLogs: false};
      case UPDATE_LOGS_SUCCESS:
        return { ...state, loading: false, updatedLogs: true };
      case UPDATE_LOGS_FAIL:
        return { ...state, loading: false, updatedLogs: false, error: action.payload };
      case FETCH_TIMELOGS_REQUEST:
        return { ...state, loading: true};
      case FETCH_TIMELOGS_SUCCESS:
        return { ...state, loading: false, timelogs: action.payload, updatedStatus: false};
      case FETCH_LOGS_REQUEST:
        return { ...state, loading: true};
      case FETCH_LOGS_SUCCESS:
        return { ...state, loading: false, logs: action.payload, updatedLogs: false};
      case FETCH_LOGS_FAIL: 
        return { ...state, loading: false, error: action.payload, updatedLogs: false};
      case RESET_TIMELOGS:
        return initialState;      
      default:
        return state;
    }
  };