import React, { useEffect } from 'react';
import { Box, useTheme, IconButton } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import { getTimeDiffInHoursFromDates, detuctMinutesFromTime, addTimes, getMondayOfCurrentWeek, formatTime } from "../../Utils";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import { CustomNoDataAvailableOverlay } from '../UI/CustomNoDataAvailableOverlay/CustomNoDataAvailableOverlay';
import { TableLoading } from '../UI/TableLoading/TableLoading';

export const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--emergency-checkout': {
    backgroundColor: "#E57373",
    '&:hover': {
      backgroundColor: "#E57373",
    },
    '&.Mui-selected': {
      backgroundColor: "#E57373",
      '&:hover': {
        backgroundColor: "#E57373",
      },
    },
  },
  '& .super-app-theme--late-checkin-checkout': {
    backgroundColor: "#FFFF00",
    '&:hover': {
      backgroundColor: "#FFFF00",
    },
    '&.Mui-selected': {
      backgroundColor: "#FFFF00",
      '&:hover': {
        backgroundColor: "#FFFF00",
      },
    },
  },
  '& .super-app-theme--normal': {
    backgroundColor: theme.palette.primary,
  },
}));

const TimelogTable = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const timelogState = useSelector((state) => state.timelog);
  const { updatedStatus } = timelogState;

  const dispatch = useDispatch();

  useEffect(() => {    
    if(updatedStatus) {
      toast.success("Updated Status Successfully");
      setTimeout(() => window.location.reload(), 800);
    } 
  }, [dispatch, updatedStatus]);

  const columns = [
    {
      field: "name",
      headerName: "Employee Name",
      flex: 1,
      valueGetter: (params) => params.row.employee.user.name
    },
    {
      field: "jobName",
      headerName: "Job Name",
      flex: 1,
      valueGetter: (params) => params.row.job.jobName
    },
    {
      field: "startingDate",
      headerName: "Scheduled Check-in Date",
      type: "date",
      flex: 1,
      valueGetter: (params) => {
        const shift = params.row.job.shiftDetails.find((shift) => shift.id === params.row.logs.shiftId);
        return shift ? new Date(shift.startingDate) : ""; 
      }
    },
    {
      field: "shiftStartTime",
      headerName: "Scheduled Check-in Time",
      flex: 1,
      valueGetter: (params) => {
        const shift = params.row.job.shiftDetails.find((shift) => shift.id === params.row.logs.shiftId);
        return shift ? shift.shiftStartTime : ""; 
      }
    },
    {
      field: "endingDate",
      headerName: "Scheduled Check-out Date",
      type: "date",
      flex: 1,
      valueGetter: (params) => {
        const shift = params.row.job.shiftDetails.find((shift) => shift.id === params.row.logs.shiftId);
        return shift ? new Date(shift.endingDate) : ""; 
      }
    },
    {
      field: "shiftEndTime",
      headerName: "Scheduled Check-out Time",
      flex: 1,
      valueGetter: (params) => {
        const shift = params.row.job.shiftDetails.find((shift) => shift.id === params.row.logs.shiftId);
        return shift ? shift.shiftEndTime : ""; 
      }
    },
    {
      field: "checkInDate",
      type: "date",
      headerName: "Checkin Date",
      flex: 1,
      valueGetter: (params) => new Date(params.row.logs.checkInDate)
    },
    {
      field: "checkIn",
      headerName: "Checkin Time",
      flex: 1,

      valueGetter: (params) => params.row.logs.checkIn
    },
    {
      field: "checkOutDate",
      type: "string",
      headerName: "Checkout Date",
      flex: 1,
      valueGetter: (params) => params.row.logs.checkOutDate ? new Date(params.row.logs.checkOutDate) : "N/A"
    },
    {
      field: "checkOut",
      headerName: "Checkout Time",
      flex: 1,
      valueGetter: (params) => params.row.logs.checkOut ? params.row.logs.checkOut : "N/A"
    },
    {
      field: "actualClockOutTime",
      headerName: "Actual Clockout Time",
      flex: 1,
      valueGetter: (params) => params.row.logs.actualClockOutTime ? params.row.logs.actualClockOutTime : "N/A"
    },
    {
      field: "checkInFeedback",
      headerName: "CheckIn Feedback",
      flex: 1,
      valueGetter: (params) => params.row.logs.checkInFeedback
    },
    {
      field: "emergencyCheckInFeedback",
      headerName: "Emergency CheckIn Feedback",
      flex: 1,
      valueGetter: (params) => params.row.logs.emergencyCheckInFeedback
    },
    {
      field: "checkOutFeedback",
      headerName: "CheckOut Feedback",
      flex: 1,
      valueGetter: (params) => params.row.logs.checkOutFeedback
    },
    {
      field: "emergencyCheckOutFeedback",
      headerName: "Emergency CheckOut Feedback",
      flex: 1,
      valueGetter: (params) => params.row.logs.emergencyCheckOutFeedback
    },
    {
      field: "breaktime",
      headerName: "Break Time",
      flex: 1,
      valueGetter: (params) => params.row.job.breakTime
    },
    {
      field: "overtime",
      headerName: "OverTime",
      flex: 1,
      valueGetter: (params) => formatTime(params.row.logs.overtime)
    },
    {
      field: "totalHours",
      headerName: "Total Hours",
      flex: 1,
      valueGetter: (params) => {

        if(params.row.logs.checkOutDate != null &&
            params.row.logs.checkOut != null) {

            let clockIn = new Date(params.row.logs.checkInDate);
            const checkinTime = params.row.logs.checkIn.split(":");
            clockIn.setHours(parseInt(checkinTime[0]), parseInt(checkinTime[1]));
    
            let clockOut = new Date(params.row.logs.checkOutDate);
            const checkoutTime = params.row.logs.checkOut.split(":");
            clockOut.setHours(parseInt(checkoutTime[0]), parseInt(checkoutTime[1]));        

            let remainingTime = detuctMinutesFromTime(getTimeDiffInHoursFromDates(clockIn, clockOut), params.row.job.breakTime);

            if(params.row.logs.approvedOvertime && params.row.logs.overtime) {
              return addTimes(remainingTime, params.row.logs.overtime);
            }

            return remainingTime;
        }

        return "";
      }
    },
    {
      field: "addOvertime",
      headerName: "Add Overtime",
      type: "action",
      flex: 1,
      renderCell: (params) => {
        return (
          params.row.logs.overtime !== "" ? 
          <Checkbox  
            checked={params.row.logs.approvedOvertime}
            disabled={true}
            onChange={(e) => {
              const updatedRows = [...params.api.getRowModels()];
              const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

              if (rowIndex !== -1) {
                updatedRows[rowIndex][1].logs.approvedOvertime = e.target.checked;
              }
            
              const rows = updatedRows.map((row) => ({
                "id": row[0],
                ...row[1],
              }));

              // Update the DataGrid with the modified rows
              params.api.updateRows(rows);
            
            }}
          /> : 
          <></>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      type: "action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ minWidth: 100 }}>
            <FormControl variant="standard" disabled={true}>
              <Select
                value={params.row.logs.status}
                label="Status"
                onChange={(e) => {
                  const updatedRows = [...params.api.getRowModels()];
                  const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

                  if (rowIndex !== -1) {
                    updatedRows[rowIndex][1].logs.status = e.target.value;
                  }
                
                  const rows = updatedRows.map((row) => ({
                    "id": row[0],
                    ...row[1],
                  }));

                  // Update the DataGrid with the modified rows
                  params.api.updateRows(rows);
                
                }}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approve">Approve</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )
      }
    }
  ];

  const [filterModel, setFilterModel] = React.useState({
    items: [
      { id: 1, field: 'checkInDate', operator: 'onOrAfter', value: getMondayOfCurrentWeek() },
      { id: 2, field: 'checkInDate', operator: 'onOrBefore', value: (new Date()).toISOString().split("T")[0]}
    ],
    logicOperator: 'and',
  });

  const handleFilterModelChange = (model) => {
    setFilterModel(model);
  };

  return (
    <Box
      id="timelog-table"
      height="65vh"
      sx={{
        "& .name-column--cell": {
          color: colors.blueAccent[300],
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <StyledDataGridPro 
        rows={props.records}
        loading={props.loading}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay:CustomNoDataAvailableOverlay,
          loadingOverlay: TableLoading
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              breaktime: false,
              checkOutDate: false,
              checkOut: false,
              addOvertime: false,
              startingDate: false,
              shiftStartTime: false,
              endingDate: false,
              shiftEndTime: false,
              checkInFeedback: false,
              emergencyCheckInFeedback: false,
              checkOutFeedback: false,
              emergencyCheckOutFeedback: false,
            },
          },
        }}      
        columns={columns}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}  
        getRowClassName={(params) => {
          return  (params.row.logs.emergencyCheckOut === true || params.row.logs.emergencyCheckIn === true) ? 
                  'super-app-theme--emergency-checkout' : 
                  ((params.row.logs.checkInFeedback && params.row.logs.checkInFeedback != "On Time") || params.row.logs.checkOutFeedback) ? 
                  'super-app-theme--late-checkin-checkout' : 'super-app-theme--normal';
        }}
      />
    </Box>
  );
};

export default TimelogTable;
