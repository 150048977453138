import axios from "axios";
import { RESET_USER } from "../services/Constants/userConstants";
import { logoutUser } from "../services/Actions/userActions";
import { toast } from "react-toastify";
import store from "../services/store";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const {dispatch} = store;

// Add a response interceptor to refresh access token if it has expired
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {

    if (error.response.status === 404 && error.response.data && error.response.data?.statusCode === 401) {
      
      dispatch(logoutUser());
      dispatch({ type: RESET_USER});
      toast.error('Session Expired!')
    }

    return Promise.reject(error);
  }
);

// Add a request interceptor to add access token to outgoing requests
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const accessToken = localStorage.getItem("access_token");
//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor to refresh access token if it has expired
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = localStorage.getItem("refresh_token");
//       if (!refreshToken) {
//         return Promise.reject(error);
//       }

//       try {
//         const response = await axios.post(`${API_URL}/auth/token/`, {
//           grant_type: "refresh_token",
//           refresh_token: refreshToken,
//           client_id: process.env.REACT_APP_CLIENT_ID,
//           client_secret: process.env.REACT_APP_CLIENT_SECRET,
//         });
//         const accessToken = response.data.access_token;
//         localStorage.setItem("access_token", accessToken);
//         localStorage.setItem("refresh_token", response.data.refresh_token);
//         axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//         return axiosInstance(originalRequest);
//       } catch (refreshError) {
//         return Promise.reject(error);
//       }
//     }

//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
