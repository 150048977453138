import React, { useState, useEffect } from 'react';
import { Box, useTheme, IconButton } from "@mui/material";
import { DataGrid, GridLoadingOverlay, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import DeleteConfirmation from '../UI/Prompt/DeleteConfirmation';
import { useDispatch, useSelector } from "react-redux";
import { deleteEmployee } from "../../services/Actions/employeeActions";
import { RESET_EMPLOYEE } from '../../services/Constants/employeeConstants';
import { toast } from "react-toastify";
import { CustomNoDataAvailableOverlay } from '../UI/CustomNoDataAvailableOverlay/CustomNoDataAvailableOverlay';
import { TableLoading } from '../UI/TableLoading/TableLoading';

const EmployeeTable = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [recordId, setRecordId] = useState(0);
  
  const handleClose = () => {
    setDeleteOpen(false);
  };

  const dispatch = useDispatch();

  const employeeState = useSelector((state) => state.employee);
  const { employeeDeleted, error } = employeeState;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });  

  useEffect(() => {
    if(employeeDeleted) {
      toast.success("Employee Deleted Successfully");
      dispatch({ type: RESET_EMPLOYEE})
      window.location.reload();
    }
  }, [employeeDeleted, dispatch]);

  useEffect(() => {
      if(error) {
          toast.error(error.message);
          dispatch({ type: RESET_EMPLOYEE})
      }
  }, [error, dispatch]);

  const handleDelete = () => {
    dispatch(deleteEmployee(recordId));
    handleClose();
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params.row.user.name
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) => params.row.user.email
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      valueGetter: (params) => params.row.user.phoneNumber
    },
  ];

  if(!props.noActions) {
    columns.push(
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 150,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (<>
              <IconButton
                aria-label="edit"
                onClick={() => {
                  navigate(`/employee/update/${params.row.id}`)
                  // handle edit action here
                }}
                sx={{
                  color: {
                    light: 'black',
                    dark: 'white',
                  },
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDeleteOpen(true);
                  setRecordId(params.row.id);
                }}
                color="error"
              >
                <Delete />
              </IconButton>
            </>
          );
        }
      }
    );
  }

  return (
    <Box
      height="65vh"
      sx={{
        "& .name-column--cell": {
          color: colors.blueAccent[300],
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      { props.noActions ?
        <DataGrid rows={props.records} 
        columns={columns}   
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      /> :
        <DataGrid rows={props.records}  
          columns={columns} 
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          loading={props.loading} 
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: CustomNoDataAvailableOverlay,
            loadingOverlay: TableLoading
          }}
        /> }
        <DeleteConfirmation open={deleteOpen} handleClose={handleClose} handleDelete={handleDelete} recordId={recordId}/>
    </Box>
  );
};

export default EmployeeTable;
