import API from "../../middleware/api";
import {
    ADD_JOB_REQUEST,
    ADD_JOB_SUCCESS,
    ADD_JOB_FAIL,
    UPDATE_JOB_REQUEST, 
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAIL, 
    DELETE_JOB_FAIL,
    DELETE_JOB_SUCCESS,
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAIL,
    FETCH_JOBS_REQUEST,
    FETCH_JOBS_SUCCESS
} from "../Constants/jobConstants";
import {getDateFromTimeString} from "../../Utils";

const api = new API();

export const addJob = (job) => async (dispatch) => {
  try {
    dispatch({ type: ADD_JOB_REQUEST });
    await api.post("/job/add", job);

    dispatch({ type: ADD_JOB_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: ADD_JOB_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
}; 

export const updateJob = (job) => async (dispatch) => {
  try {

    dispatch({ type: UPDATE_JOB_REQUEST });
    await api.put("/job/update", job);

    dispatch({ type: UPDATE_JOB_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: UPDATE_JOB_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const deleteJob = (id) => async (dispatch) => {
  try {
    await api.delete(`/job/${id}`);

    dispatch({ type: DELETE_JOB_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: DELETE_JOB_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const fetchJobs = (id) => async (dispatch) => {
      dispatch({ type: FETCH_JOBS_REQUEST });    
      const { data } = await api.get(`/jobs/employer/${id}`);
      dispatch({ type: FETCH_JOBS_SUCCESS, payload: data });    
};

export const getJob = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_JOB_REQUEST });
    const { data } = await api.get(`/job/${id}`);

    dispatch({ 
      type: GET_JOB_SUCCESS, 
      payload: {
        ...data, 
        shiftDetails: data.shiftDetails.map((shift) => {
          return {
              shiftId: shift.id,
              shiftStartEndDate: [new Date(shift.startingDate), new Date(shift.endingDate)], 
              shiftStartEndTime: [getDateFromTimeString(shift.shiftStartTime), getDateFromTimeString(shift.shiftEndTime)]
          }
        })
      } 
    });      
  } catch (error) {

    dispatch({
      type: GET_JOB_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};
