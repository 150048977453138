import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import EmployerForm from "../../components/Empoyer/EmployerForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEmployer, updateEmployer } from "../../services/Actions/employerActions";
import { RESET_EMPLOYER } from '../../services/Constants/employerConstants';
import { toast } from "react-toastify";
import OverlayLoading from "../../components/UI/OverlayLoading/OverlayLoading";
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

const EditEmployer = () => {
    const small = useMediaQuery("(min-width:600px)");

    const employerState = useSelector((state) => state.employer);
    const { employer, error, employerUpdated, loading } = employerState;

    const initialValues = {
        name: "",
        email: "",
        organizationName: "",
        password: "",
        numberOfEmployee: "",
        binary: false
    };

    const handleFormSubmit = (values) => {
        delete values.user;
        dispatch(updateEmployer({...values, id}));
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
  
    useEffect(() => {    
        dispatch(getEmployer(id));
    }, [dispatch, id]);

    useEffect(() => {
        console.log(employer);
        if(employerUpdated) {
          toast.success("Employer Updated Successfully");
          dispatch({ type: RESET_EMPLOYER});
          navigate("/employers");
        }
    }, [employerUpdated, dispatch]);

    useEffect(() => {
        if(error) {
            toast.error(error.message);
            dispatch({ type: RESET_EMPLOYER})
        }
    }, [error, dispatch]);  

    return (
        <Layout>
            <Box>
                <Box m="20px">
                    <PageHeader title="EDIT EMPLOYER" subtitle="Update Employer Information" />
                    <Box display="flex">
                        <Box sx={{ flex: 1 }}>
                            { !loading && <EmployerForm initialValues={employer ? employer : initialValues} handleFormSubmit={handleFormSubmit} btnText={"Update Employer"}/> }
                        </Box>
                        { small && <Box sx={{ flex: 1 }}></Box>}
                    </Box>
                </Box>
                { loading && <OverlayLoading loading={loading}/> }             
            </Box>
        
        </Layout>
    );
};

export default EditEmployer;
