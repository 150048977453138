import { Box } from '@mui/material';
import logo from '../../../src/logo-only.png';

const LogoBackground = () => {
    return (
        <Box sx={{ position: 'absolute', zIndex: -1, bottom: "100px" }}>
            <Box 
                component="img" 
                sx={{
                    width: "500px",
                    height: "80%"
                }}
                src={logo} 
                alt="logo" 
            />
        </Box>
    );
};

export default LogoBackground;