import React, {useEffect} from 'react';
import { Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import EmployerForm from "../../components/Empoyer/EmployerForm";
import { useDispatch, useSelector } from "react-redux";
import { addEmployer } from "../../services/Actions/employerActions";
import { RESET_EMPLOYER } from "../../services/Constants/employerConstants";
import { toast } from "react-toastify";
import useMediaQuery from '@mui/material/useMediaQuery';

const AddEmployer = () => {
  const small = useMediaQuery("(min-width:600px)");

  const initialValues = {
    name: "",
    email: "",
    organizationName: "",
    password: "",
    numberOfEmployee: "",
    binary: false
  };

  const dispatch = useDispatch();

  const employerState = useSelector((state) => state.employer);
  const { loading, employerAdded, error } = employerState;

  useEffect(() => {
      if(employerAdded) {
        toast.success("Employer Added Successfully");
        dispatch({ type: RESET_EMPLOYER})
      }
  }, [employerAdded, dispatch]);

  useEffect(() => {
      if(error) {
          toast.error(error.message);
          dispatch({ type: RESET_EMPLOYER})
      }
  }, [error, dispatch]);

  const handleFormSubmit = (employer) => {
    dispatch(addEmployer(employer));
  };

  return (
    <Layout>
        <Box>
            <Box m="20px">
                <PageHeader title="ADD EMPLOYER" subtitle="Add a new Employer" />
                <Box display="flex">
                    <Box sx={{ flex: 1 }}>
                        <EmployerForm initialValues={initialValues} handleFormSubmit={handleFormSubmit} btnText={"Add Employer"} loading={loading}/>
                    </Box>
                    { small && <Box sx={{ flex: 1 }}></Box>}
                </Box>
            </Box>             
        </Box>      
    </Layout>
  );
};

export default AddEmployer;
