import * as React from 'react';
import { useRef } from 'react';
import { Box, TextField } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from "yup";
import { Formik } from 'formik';
import LoadingButton from "@mui/lab/LoadingButton";
import { MuiTelInput } from 'mui-tel-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const EmployeeForm = (props) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const imageRef = useRef(null);

    const checkoutSchema = yup.object().shape({
        name: yup
        .string()
        .required("Please enter the name")
        .max(50, "Name must be at most 50 characters")
        .test("notOnlySpaces", "Please enter a valid name", (value) => {
          return value.trim().length > 0;
        }),
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Please enter the email")
        .test("notOnlySpaces", "Email cannot be only spaces", (value) => {
          return value.trim().length > 0;
        }),
        phoneNumber: yup.string().required("Please enter the Phone Number").test('valid', "Invalid Phone Number", (phone) => {
            const parsedPhoneNumber = parsePhoneNumberFromString(phone);
            return parsedPhoneNumber ? (parsedPhoneNumber.isValid()) : false;                        
        }),

        password: yup.string()
            .required('Please Enter your password')
            .min(8, 'The password must contain 8 characters including an upper case letter, a number and a special character.')
            .matches(/[A-Za-z]/, 'The password must contain 8 characters including an upper case letter, a number and a special character')
            .matches(/\d/, 'The password must contain 8 characters including an upper case letter, a number and a special character')
            .matches(/[!@#$%^&*?_\-]/, 'The password must contain 8 characters including an upper case letter, a number and a special character'),
        image: yup.mixed().required('Please upload an image').test("fileType", "Please upload .jpg, .jpeg and .png files only.", (value) => {
            if (!value) {
              return true;
            }
            return value && ["image/jpeg", "image/jpeg", "image/png"].includes(value.type);
          }),
    });

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return(
        <Formik
            onSubmit={(values, { resetForm, setFieldValue }) => {
                const formData = new FormData();

                formData.append('name', values.name);
                formData.append('email', values.email);
                formData.append('phoneNumber', values.phoneNumber);
                formData.append('password', values.password);

                if(typeof values.image != "string") {
                    formData.append('image', values.image);
                }

                props.handleFormSubmit(formData);
                if(props.btnText === "Add Employee") {
                    resetForm(props.initialValues);
                    if (imageRef.current) {
                        imageRef.current.value = '';
                    }
                  
                }

            }}
            initialValues={props.initialValues}
            validationSchema={checkoutSchema}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Name*"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            name="name"
                            error={!!touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Email*"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={!!touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <MuiTelInput 
                            fullWidth
                            variant="filled"                
                            label="Phone Number*"
                            onBlur={handleBlur}
                            onChange={(phone) => {
                                handleChange({
                                    target: {
                                        name: "phoneNumber",
                                        value: phone
                                    }
                                });
                                handleChange(phone);
                            }}                    
                            defaultCountry="GB"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            error={!!touched.phoneNumber && !!errors.phoneNumber}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <FormControl 
                            variant="filled" 
                            sx={{ gridColumn: "span 4" }} 
                            error={!!touched.password && !!errors.password}
                        >
                            <InputLabel htmlFor="filled-adornment-password">Password*</InputLabel>
                            <FilledInput
                                id="filled-adornment-password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"   
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            <FormHelperText>{touched.password && errors.password}</FormHelperText>
                        </FormControl>                
                        {values.image && <img src={typeof values.image === "object" ? URL.createObjectURL(values.image) : values.image} alt="Invalid or Broken link" style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain",
                        }}/>}                
                        <TextField
                            inputRef={imageRef}
                            fullWidth
                            label="Picture*"
                            variant="filled"
                            type="file"
                            InputLabelProps={{ shrink: true }}
                            accept="image/*"
                            onBlur={handleBlur}
                            onChange={(event) => setFieldValue('image', event.currentTarget.files[0])}
                            name="image"
                            error={!!touched.image && !!errors.image}
                            helperText={touched.image && errors.image}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="end" mt="20px">
                        <LoadingButton type="submit" loading={props.loading} color="secondary" variant="contained">
                            {props.btnText}
                        </LoadingButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
}

export default EmployeeForm;
