import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";

const StatBox = ({ title, subtitle, icon, path }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Box 
      width="100%"
      m="0 30px" 
      sx={{
        cursor: "pointer"
      }}
      onClick={
        () => {
          if(path) {
            navigate(path)
          }
        }
      }
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          {icon}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.blueAccent[500] }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
