import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OverlayLoading from "../../components/UI/OverlayLoading/OverlayLoading";
import { useNavigate } from "react-router-dom";
import { RESET_JOB } from '../../services/Constants/jobConstants';
import JobForm from "../../components/Job/JobForm";
import { useDispatch, useSelector } from "react-redux";
import { updateJob, getJob } from '../../services/Actions/jobActions';

const EditJob = () => {

    const jobState = useSelector((state) => state.jobData);
    const { job, error, jobUpdated, loading } = jobState;

    const initialValues = {
        jobName: "",
        jobDescription: "",
        lineManager: "",
        longitude: "",
        latitude: "",
        employeeId: "",
        thresholdTime: "",
        breakTime: "",
        offDays: [],
        shiftDetails: [{"shiftStartEndDate": [], "shiftStartEndTime": []}]
    };

    const userData = useSelector((state) => state.userData);

    const handleFormSubmit = (values) => {        
        dispatch(updateJob({...values, "jobId": id, 'employerId': userData.user.id}));
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
  
    useEffect(() => {    
        dispatch(getJob(id));
    }, [dispatch, id]);

    useEffect(() => {
        if(jobUpdated) {
          toast.success("Job Updated Successfully");
          dispatch({ type: RESET_JOB});
          navigate("/jobs");
        }
    }, [jobUpdated, dispatch]);

    useEffect(() => {
        if(error) {
            toast.error(error.message);
            dispatch({ type: RESET_JOB})
        }
    }, [error, dispatch]);  


    return (
        <Layout type="Employer">
            <Box>
                <Box m="20px">
                    <PageHeader title="EDIT JOB" subtitle="Update Job Information" />
                    <Box display="flex">
                        <Box sx={{ flex: 1 }}>
                            { !loading && <JobForm initialValues={job ? job : initialValues} handleFormSubmit={handleFormSubmit} btnText={"Update Job"}/> }
                        </Box>
                    </Box>
                </Box>
                { loading && <OverlayLoading loading={loading}/> }             
            </Box>
        
        </Layout>
    );
};

export default EditJob;
