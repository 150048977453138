export const ADD_JOB_REQUEST = "ADD_JOB_REQUEST";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_FAIL = "ADD_JOB_FAIL";

export const UPDATE_JOB_REQUEST = "UPDATE_JOB_REQUEST"; 
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAIL = "UPDATE_JOB_FAIL"; 

export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL"; 
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";

export const GET_JOB_REQUEST = "GET_JOB_REQUEST";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAIL = "GET_JOB_FAIL";

export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";


export const RESET_JOB = "RESET_JOB"; 