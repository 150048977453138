import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography, useTheme } from "@mui/material";
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControl from '@mui/material/FormControl';
import { tokens } from "../../theme";
import { updateWeeklyEmailNotification } from "../../services/Actions/employerActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET_EMPLOYER } from '../../services/Constants/employerConstants';

const EmployerSetting = () => {
    const initialValues = {
        weeklyEmailNotification: true
    };

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();

    const userState = useSelector((state) => state.userData);
    const { user } = userState;

    const employerState = useSelector((state) => state.employer);
    const { weeklyEmailNotificationUpdated, error } = employerState;

    const [weeklyEmailNotificationSwitch, setWeeklyEmailNotificationSwitch] = useState(user.emailPreference);

    const handleEmailPrefChange = (e) => {
        setWeeklyEmailNotificationSwitch(e.target.checked);
        dispatch(updateWeeklyEmailNotification(user.id, e.target.checked));
    };

    useEffect(() => {
        if(weeklyEmailNotificationUpdated) {
            toast.success("Weekly Email Notification Status has been updated successfully.");
            dispatch({ type: RESET_EMPLOYER});
        }
    }, [weeklyEmailNotificationUpdated, dispatch]);

    useEffect(() => {
        if(error) {
            toast.error(error.message);
            dispatch({ type: RESET_EMPLOYER});
        }
    }, [error, dispatch]);
    
    return(
        <Formik
            // onSubmit={(values) => {
            //     console.log(values);
                //handleFormSubmit(values);
            // }}
            initialValues={initialValues}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    display="grid"
                    gap="25px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                >
                    <FormControl 
                        variant="filled" 
                        sx={{ gridColumn: "span 4" }} 
                        error={!!touched.weeklyEmailNotification && !!errors.weeklyEmailNotification}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Typography
                                variant="h5"
                                color={colors.greenAccent[500]}
                            >
                                Turn ON/OFF Weekly Email Notifications
                            </Typography>
                            <Switch 
                                label="Weekly Email Notifications" 
                                name="weeklyEmailNotification" 
                                onChange={handleEmailPrefChange}
                                checked={weeklyEmailNotificationSwitch}
                            />
                        </Box>
                    </FormControl>                   
                </Box>
                {/* APPLY CHNAGES SHOULD BE ON SWITCH TOGGLE */}
                {/* <Box display="flex" justifyContent="end" mt="20px">
                    <LoadingButton type="submit" loading={false} color="secondary" variant="contained">
                        Apply Changes
                    </LoadingButton>
                </Box> */}
            </form>
            )}
        </Formik>
    );
}

export default EmployerSetting;
