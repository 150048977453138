
// Format YYYY-mm-dd
export const getFormatedDate = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
}

export const getSimpleFormatDate = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
}

export const get24HourFormatTime = (date) => {
    const time = new Date(date);

    const hours = time.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if needed
    const minutes = time.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if needed

    return `${hours}:${minutes}`;
}

export const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export const getDateFromTimeString = (timeString) => {
    const currentTime = new Date();
    const [hours, minutes] = timeString.split(':').map(Number);
  
    // Set the hours and minutes of the current date
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0); // Optionally, set seconds to 0
  
    return currentTime;
}

export const getTimeDiffInHoursFromDates = (dt1, dt2) => {
    let diff =(dt2.getTime() - dt1.getTime());

    // Calculate hours and minutes
    let hours = Math.floor(diff / (1000 * 60 * 60));
    let minutes = Math.floor((diff / (1000 * 60)) % 60);

    // Format the time
    hours = hours > 9 ? hours : '0'+hours;
    minutes = minutes > 9 ? minutes : '0'+minutes;

    return `${hours}:${minutes}`;

} 
  
export const getStartEndDateTimeObjects = (shift) => {    
    let [newStartDate, newEndDate] = shift.shiftStartEndDate;
    let [newStartTime, newEndTime] = shift.shiftStartEndTime;

    newStartDate = new Date(newStartDate);
    newEndDate = new Date(newEndDate);

    newStartTime = get24HourFormatTime(newStartTime);

    newEndTime = get24HourFormatTime(newEndTime);

    newStartDate.setHours(0);
    newStartDate.setMinutes(0);
    newStartDate.setSeconds(0);                     
    newStartDate.setMilliseconds(0);

    newEndDate.setHours(0);
    newEndDate.setMinutes(0);
    newEndDate.setSeconds(0);
    newEndDate.setMilliseconds(0);

    return [newStartDate, newEndDate, newStartTime, newEndTime];
}

export const detuctMinutesFromTime = (timeString, minutesToDetuct) => {

    // Split the time string into hours and minutes
    let [hours, minutes] = timeString.split(":");

    // Convert hours and minutes to numbers
    const hoursValue = parseInt(hours, 10);
    const minutesValue = parseInt(minutes, 10);

    // Calculate the total minutes
    const totalMinutes = hoursValue * 60 + minutesValue;
    const remainingMinutes = totalMinutes - minutesToDetuct;
    
    if(remainingMinutes > 0) {
        // Calculate the hours and remaining minutes
        hours = Math.floor(remainingMinutes / 60);
        minutes = remainingMinutes % 60;
    } else {
        minutes = hours = 0;
    }

    // Format the time
    hours = hours > 9 ? hours : '0'+hours;
    minutes = minutes > 9 ? minutes : '0'+minutes;    


    return `${hours}:${minutes}`;
};

export function timeToMinutes(time) {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
}

// Function to convert minutes to time string
export function minutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}`;
}

export function formatTime(input) {
    if(!input) {
        return "00:00"; 
    }

    const [hours, minutes] = input.split(':').map(Number);

    // Check if both hours and minutes are defined and not NaN
    if (!isNaN(hours) && !isNaN(minutes)) {
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }

    // If input is not in the expected format, return the input as is
    return input;
}
  

export const addTimes = (time1, time2) => {
    
    // Create Date objects for the two times
    const minutes1 = timeToMinutes(time1);
    const minutes2 = timeToMinutes(time2);
    
    // Add the minutes together
    const sumMinutes = minutes1 + minutes2;
    
    // Convert the sum back to time string
    return minutesToTime(sumMinutes);    
};

export const isSubPage = (pathname) => {
    const regex = /\/[^/]+\/[^/]*[^/]+/;
    return regex.test(pathname);
}

export const getMondayOfCurrentWeek = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysUntilMonday = currentDay === 0 ? 6 : currentDay - 1;
  
    const mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - daysUntilMonday);
  
    return mondayDate.toISOString().split("T")[0];
} 