import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    LOGOUT_USER,
    UPDATE_USER_PREFRENCES,
    RESET_USER
  } from "../Constants/userConstants";
  
  const userPrefrencesFromStorage =
    localStorage.getItem("user_prefrences") !== undefined && localStorage.getItem("user_prefrences") !== null
      ? localStorage.getItem("user_prefrences")
      : JSON.stringify({ mode: "light" });
  
  const initialState = {
    user: JSON.parse(localStorage.getItem("user")),
    token: localStorage.getItem("access_token") ? localStorage.getItem("access_token") : null,
    userPrefrences: JSON.parse(userPrefrencesFromStorage),
    passwordUpdate: false,
    loading: false,
    error: ""
  };
  
  export const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_USER_REQUEST:
        return { ...state, loading: true };
      case LOGIN_USER_SUCCESS:
        return { ...state, loading: false, user: action.payload };
      case LOGIN_USER_FAIL:
        return { ...state, loading: false, error: action.payload };
      case UPDATE_PASSWORD_REQUEST:
        return { ...state, loading: true };
      case UPDATE_PASSWORD_SUCCESS:
        return { ...state, loading: false, passwordUpdate: action.payload };
      case UPDATE_PASSWORD_FAIL:
        return { ...state, loading: false, error: action.payload };
      case LOGOUT_USER:
        return { ...state, user: null, token: null };
      case UPDATE_USER_PREFRENCES:
        return {
          ...state,
          userPrefrences: action.payload,
        };      
      case RESET_USER:
        return {
          ...state,
          passwordUpdate: false,
          loading: false,
          error: ""
        };      
      default:
        return state;
    }
  };