import * as React from 'react';
import { Box, TextField, Typography,useTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from "yup";
import { Formik } from 'formik';
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Switch from '@mui/material/Switch';
import { tokens } from "../../theme";



const EmployerForm = (props) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const digitsRegExp = /^\d+$/;

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const checkoutSchema = yup.object().shape({
        name: yup
        .string()
        .required("Please enter the name")
        .max(50, "Name must be at most 50 characters")
        .test("notOnlySpaces", "Please enter a valid name", (value) => {
          return value.trim().length > 0;
        }),
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Please enter the email")
        .test("notOnlySpaces", "Email cannot be only spaces", (value) => {
          return value.trim().length > 0;
        }),
      organizationName: yup
        .string()
        .required("Please enter the Organization Name")
        .test("notOnlySpaces", "Organization Name cannot be only spaces", (value) => {
          return value.trim().length > 0;
        }),
        password: yup.string()
            .required('Please Enter your password')
            .min(8, 'The password must contain 8 characters including an upper case letter, a number and a special character.')
            .matches(/[A-Za-z]/, 'The password must contain 8 characters including an upper case letter, a number and a special character')
            .matches(/\d/, 'The password must contain 8 characters including an upper case letter, a number and a special character')
            .matches(/[!@#$%^&*?_\-]/, 'The password must contain 8 characters including an upper case letter, a number and a special character'),
        numberOfEmployee: yup.string().matches(digitsRegExp, 'Max Employees has to be greater than equal to 1').required("Please enter the Max Employees")
    });
    
    return(
        <Formik
            onSubmit={(values, { resetForm }) => {
                props.handleFormSubmit(values);
                if(props.btnText === "Add Employer") {
                    resetForm(props.initialValues)
                }

            }}
            initialValues={props.initialValues}
            validationSchema={checkoutSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 4" }}
                />                   
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Organization Name*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.organizationName}
                    name="organizationName"
                    error={!!touched.organizationName && !!errors.organizationName}
                    helperText={touched.organizationName && errors.organizationName}
                    sx={{ gridColumn: "span 4" }}
                />
                <FormControl 
                        variant="filled" 
                        sx={{ gridColumn: "span 4" }} 
                        error={!!touched.password && !!errors.password}
                    >
                    <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                    <FilledInput
                        id="filled-adornment-password"
                        label="Password*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"   
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    <FormHelperText>{touched.password && errors.password}</FormHelperText>
                </FormControl>   
                <FormControl 
                        variant="filled" 
                        sx={{ gridColumn: "span 4" }} 
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Typography
                                variant="h5"
                                color={colors.greenAccent[500]}
                            >
                                Show logs in 0-1 format in email?
                            </Typography>
                            <Switch
                                value={values.binary}
                                checked={values.binary}
                                onChange={handleChange}
                                label="binary"
                                name="binary"
                            />
                        </Box>
                    </FormControl>
                <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Max Employees*"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.numberOfEmployee}
                    name="numberOfEmployee"
                    error={!!touched.numberOfEmployee && !!errors.numberOfEmployee}
                    helperText={touched.numberOfEmployee && errors.numberOfEmployee}
                    sx={{ gridColumn: "span 4" }}
                />

                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                    <LoadingButton type="submit" loading={props.loading} color="secondary" variant="contained">
                        {props.btnText}
                    </LoadingButton>
                </Box>
            </form>
            )}
        </Formik>
    );
}

export default EmployerForm;
