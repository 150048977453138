import API from "../../middleware/api";
import {
    ADD_EMPLOYER_REQUEST,
    ADD_EMPLOYER_SUCCESS,
    ADD_EMPLOYER_FAIL,
    UPDATE_EMPLOYER_REQUEST, 
    UPDATE_EMPLOYER_SUCCESS,
    UPDATE_EMPLOYER_FAIL, 
    DELETE_EMPLOYER_FAIL,
    DELETE_EMPLOYER_SUCCESS,
    GET_EMPLOYER_REQUEST,
    GET_EMPLOYER_SUCCESS,
    GET_EMPLOYER_FAIL,
    FETCH_EMPLOYERS_REQUEST,
    FETCH_EMPLOYERS_SUCCESS,
    EMPLOYER_DASHBOARD_REQUEST,
    EMPLOYER_DASHBOARD_SUCCESS,
    EMPLOYER_DASHBOARD_FAIL,
    UPDATE_WEEKLYEMAILNOTIFICATION_REQUEST,
    UPDATE_WEEKLYEMAILNOTIFICATION_SUCCESS,
    UPDATE_WEEKLYEMAILNOTIFICATION_FAIL
} from "../Constants/employerConstants";
import { LOGIN_USER_SUCCESS } from "../Constants/userConstants";

const api = new API();

export const addEmployer = (employer) => async (dispatch) => {
  try {
    dispatch({ type: ADD_EMPLOYER_REQUEST });
    await api.post("/employer/add", {...employer, type: 2});

    dispatch({ type: ADD_EMPLOYER_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: ADD_EMPLOYER_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
}; 

export const updateEmployer = (employer) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EMPLOYER_REQUEST });
    await api.put("/employer/update", {...employer, type: 2});

    dispatch({ type: UPDATE_EMPLOYER_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: UPDATE_EMPLOYER_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const deleteEmployer = (id) => async (dispatch) => {
  try {
    await api.delete(`/employer/${id}`);

    dispatch({ type: DELETE_EMPLOYER_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: DELETE_EMPLOYER_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const fetchEmployers = () => async (dispatch) => {
      dispatch({ type: FETCH_EMPLOYERS_REQUEST });    
      const { data } = await api.get("/employers");
      dispatch({ type: FETCH_EMPLOYERS_SUCCESS, payload: [...data].reverse() });    
};

export const getEmployer = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_EMPLOYER_REQUEST });
    const { data } = await api.get(`/employer/${id}`);
    
    const employer = {
      ...data,
      ...data.user
    };    
    delete data.user;

    dispatch({ type: GET_EMPLOYER_SUCCESS, payload: employer });      
  } catch (error) {

    dispatch({
      type: GET_EMPLOYER_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const getDashboardCount = (employerId) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYER_DASHBOARD_REQUEST });
    const {data} = await api.get(`/employer/count/${employerId}`);

    dispatch({ type: EMPLOYER_DASHBOARD_SUCCESS, payload: data });
  } catch (error) {

    dispatch({
      type: EMPLOYER_DASHBOARD_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
}; 

export const updateWeeklyEmailNotification = (employerId, weeklyEmailNotification) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_WEEKLYEMAILNOTIFICATION_REQUEST });

    let formdata = new FormData();
    formdata.append("employerId", employerId);
    formdata.append("emailPreference", weeklyEmailNotification);
    const {data} = await api.put('/employer/emailPreference', formdata);

    let user = data.user;
    localStorage.setItem("user", JSON.stringify({...user, ...data}));
    
    dispatch({ type: LOGIN_USER_SUCCESS, payload: {...user, ...data}});

    dispatch({ type: UPDATE_WEEKLYEMAILNOTIFICATION_SUCCESS, payload: data });
  } catch (error) {

    dispatch({
      type: UPDATE_WEEKLYEMAILNOTIFICATION_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};