export const ADD_EMPLOYER_REQUEST = "ADD_EMPLOYER_REQUEST";
export const ADD_EMPLOYER_SUCCESS = "ADD_EMPLOYER_SUCCESS";
export const ADD_EMPLOYER_FAIL = "ADD_EMPLOYER_FAIL";

export const UPDATE_EMPLOYER_REQUEST = "UPDATE_EMPLOYER_REQUEST"; 
export const UPDATE_EMPLOYER_SUCCESS = "UPDATE_EMPLOYER_SUCCESS";
export const UPDATE_EMPLOYER_FAIL = "UPDATE_EMPLOYER_FAIL"; 

export const DELETE_EMPLOYER_FAIL = "DELETE_EMPLOYER_FAIL"; 
export const DELETE_EMPLOYER_SUCCESS = "DELETE_EMPLOYER_SUCCESS";

export const GET_EMPLOYER_REQUEST = "GET_EMPLOYER_REQUEST";
export const GET_EMPLOYER_SUCCESS = "GET_EMPLOYER_SUCCESS";
export const GET_EMPLOYER_FAIL = "GET_EMPLOYER_FAIL";

export const EMPLOYER_DASHBOARD_REQUEST = "EMPLOYER_DASHBOARD_REQUEST";
export const EMPLOYER_DASHBOARD_SUCCESS = "EMPLOYER_DASHBOARD_SUCCESS";
export const EMPLOYER_DASHBOARD_FAIL = "EMPLOYER_DASHBOARD_FAIL";

export const FETCH_EMPLOYERS_REQUEST = "FETCH_EMPLOYERS_REQUEST";
export const FETCH_EMPLOYERS_SUCCESS = "FETCH_EMPLOYERS_SUCCESS";

export const UPDATE_WEEKLYEMAILNOTIFICATION_REQUEST = "UPDATE_WEEKLYEMAILNOTIFICATION_REQUEST";
export const UPDATE_WEEKLYEMAILNOTIFICATION_SUCCESS = "UPDATE_WEEKLYEMAILNOTIFICATION_SUCCESS";
export const UPDATE_WEEKLYEMAILNOTIFICATION_FAIL = "UPDATE_WEEKLYEMAILNOTIFICATION_FAIL";

export const RESET_EMPLOYER = "RESET_EMPLOYER"; 