import React, { useEffect } from 'react';
import Layout from "../../components/Layout/Layout";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PageHeader from "../../components/PageHeader/PageHeader";
import StatBox from "../../components/UI/StatBox/StatBox";
import WorkIcon from '@mui/icons-material/Work';
import Groups2Icon from '@mui/icons-material/Groups2';
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getDashboardCount } from '../../services/Actions/employerActions';
import OverlayLoading from '../../components/UI/OverlayLoading/OverlayLoading';

function EmployerDashboard() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();

    const userState = useSelector((state) => state.userData);
    const { user } = userState;  

    const employerState = useSelector((state) => state.employer);
    const { dashboardData, loading } = employerState;
    const small = useMediaQuery("(max-width: 600px)");

    useEffect(() => {    
        dispatch(getDashboardCount(user.id));
    }, []);
    
    return (
        <Layout type="Employer">
            <Box m="20px">
            {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="DASHBOARD" subtitle="Welcome to your dashboard" />
                </Box>

                {/* GRID & CHARTS */}
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gap="20px"
                >
                    {/* ROW 1 */}                
                    <Box
                        gridColumn={small ? "span 12" : "span 4"}
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                    <StatBox
                        title={dashboardData?.employeeCount}
                        subtitle="Employees"
                        progress="0.50"
                        path="/employees"
                        increase="+21%"
                        icon={
                            <Groups2Icon
                                sx={{ color: colors.blueAccent[500], fontSize: "30px" }}
                            />
                        }
                    />
                    </Box>
                    <Box
                        gridColumn={small ? "span 12" : "span 4"}
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                    <StatBox
                        title={dashboardData?.jobCount}
                        subtitle="Jobs"
                        progress="0.30"
                        increase="+5%"
                        path="/jobs"
                        icon={
                        <WorkIcon
                            sx={{ color: colors.blueAccent[500], fontSize: "30px" }}
                        />
                        }
                    />
                    </Box>                                    
                </Box>
                { loading && <OverlayLoading loading={loading}/> }             
            </Box>
        </Layout>
    );
}

export default EmployerDashboard;