import API from "../../middleware/api";
import {
    ADD_EMPLOYEE_REQUEST,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAIL,
    UPDATE_EMPLOYEE_REQUEST, 
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL, 
    DELETE_EMPLOYEE_FAIL,
    DELETE_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_REQUEST,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAIL,
    FETCH_EMPLOYEES_REQUEST,
    FETCH_EMPLOYEES_SUCCESS
} from "../Constants/employeeConstants";

const api = new API();

export const addEmployee = (employee) => async (dispatch) => {
  try {
    dispatch({ type: ADD_EMPLOYEE_REQUEST });
    await api.post("/employee/add", employee);

    // let formData = new FormData();
    // formData.append("image", employee.get("image"));
    // formData.append("userId", data.id);

    // await api.post("https://face-recognization-api.onrender.com/enroll", formData);

    dispatch({ type: ADD_EMPLOYEE_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: ADD_EMPLOYEE_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
}; 

export const updateEmployee = (employee) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EMPLOYEE_REQUEST });

    let obj = {};
    employee.forEach(function(value, key){
      obj[key] = value;
    });  

    await api.put("/employee/update", employee);

    // if(employee.get("image") != null && employee.get("image")) {
    //   let formData = new FormData();
    //   formData.append("image", employee.get("image"));
    //   formData.append("userId", employee.get("id"));
  
    //   await api.post("https://face-recognization-api.onrender.com/enroll", formData);  
    // }


    dispatch({ type: UPDATE_EMPLOYEE_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: UPDATE_EMPLOYEE_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const deleteEmployee = (id) => async (dispatch) => {
  try {
    await api.delete(`/employee/${id}`);

    dispatch({ type: DELETE_EMPLOYEE_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: DELETE_EMPLOYEE_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const fetchEmployees = (id) => async (dispatch) => {
    dispatch({ type: FETCH_EMPLOYEES_REQUEST });
    const { data } = await api.get(`/employees/${id}`);
    dispatch({ type: FETCH_EMPLOYEES_SUCCESS, payload: data });    
};

export const getEmployee = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_EMPLOYEE_REQUEST });
    const { data } = await api.get(`/employee/${id}`);
    
    const employee = {
      ...data,
      ...data.user,
      image: data.imageURL
    };    
    delete data.user;

    dispatch({ type: GET_EMPLOYEE_SUCCESS, payload: employee });      
  } catch (error) {

    dispatch({
      type: GET_EMPLOYEE_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

