import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

function DeleteConfirmation(prop) {

  return (
    <div>
      <Dialog open={prop.open} onClose={prop.handleClose}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          {"msg" in prop ? prop.msg : "Are you sure you want to delete this record?"}          
        </DialogContent>
        <DialogActions>
          <Button onClick={prop.handleClose} color="generic">Cancel</Button>
          <Button onClick={prop.handleDelete} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteConfirmation;