import React, {useEffect} from 'react';
import { Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import EmployeeForm from "../../components/Employee/EmployeeForm";
import { useDispatch, useSelector } from "react-redux";
import { addEmployee } from "../../services/Actions/employeeActions";
import { RESET_EMPLOYEE } from "../../services/Constants/employeeConstants";
import { toast } from "react-toastify";
import useMediaQuery from '@mui/material/useMediaQuery';

const AddEmployee = () => {
  const small = useMediaQuery("(min-width:600px)");

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "+44",
    password: "",
    image: ''
  };

  const dispatch = useDispatch();

  const employeeState = useSelector((state) => state.employee);
  const { loading, employeeAdded, error } = employeeState;

  const userData = useSelector((state) => state.userData);

  useEffect(() => {
      if(employeeAdded) {
        toast.success("Employee Added Successfully");
        dispatch({ type: RESET_EMPLOYEE})
      }
  }, [employeeAdded, dispatch]);

  useEffect(() => {
      if(error) {
          toast.error(error.message);
          dispatch({ type: RESET_EMPLOYEE})
      }
  }, [error, dispatch]);

  const handleFormSubmit = (employee) => {
    employee.append('employerId', userData.user.id);

    dispatch(addEmployee(employee));
  };

  return (
    <Layout type="Employer">
        <Box>
            <Box m="20px">
                <PageHeader title="ADD EMPLOYEE" subtitle="Add a new Employee" />
                <Box display="flex">
                    <Box sx={{ flex: 1 }}>
                        <EmployeeForm initialValues={initialValues} handleFormSubmit={handleFormSubmit} btnText={"Add Employee"} loading={loading}/>
                    </Box>
                    { small && <Box sx={{ flex: 1 }}></Box>}
                </Box>
            </Box>             
        </Box>      
    </Layout>
  );
};

export default AddEmployee;
