import {
    ADD_EMPLOYEE_REQUEST,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAIL,
    UPDATE_EMPLOYEE_REQUEST,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL,
    DELETE_EMPLOYEE_FAIL,
    DELETE_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_REQUEST,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_FAIL,
    FETCH_EMPLOYEES_REQUEST,
    FETCH_EMPLOYEES_SUCCESS,
    RESET_EMPLOYEE
  } from "../Constants/employeeConstants";
  
  const initialState = {
    employeeAdded: false,
    employeeUpdated: false,
    employeeDeleted: false,
    employees: [],
    employee: null,
    loading: false,
    error: null
  };
  
  export const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_EMPLOYEE_REQUEST:
        return { ...state, loading: true };
      case ADD_EMPLOYEE_SUCCESS:
        return { ...state, loading: false, employeeAdded: action.payload };
      case ADD_EMPLOYEE_FAIL:
        return { ...state, loading: false, error: action.payload };
      case UPDATE_EMPLOYEE_REQUEST:
        return { ...state, loading: true };
      case UPDATE_EMPLOYEE_SUCCESS:
        return { ...state, loading: false, employeeUpdated: action.payload };
      case UPDATE_EMPLOYEE_FAIL:
        return { ...state, loading: false, error: action.payload };
      case DELETE_EMPLOYEE_SUCCESS:
        return { ...state, loading: false, employeeDeleted: action.payload };
      case DELETE_EMPLOYEE_FAIL:
        return { ...state, loading: false, error: action.payload };
      case GET_EMPLOYEE_REQUEST:
        return { ...state, loading: true };
      case GET_EMPLOYEE_SUCCESS:
        return { ...state, loading: false, employee: action.payload };
      case GET_EMPLOYEE_FAIL:
        return { ...state, loading: false, error: action.payload };
      case FETCH_EMPLOYEES_REQUEST:
        return { ...state, loading: true, employees: [] };
      case FETCH_EMPLOYEES_SUCCESS:
        return { ...state, loading: false, employees: action.payload };
      case RESET_EMPLOYEE:
        return initialState;      
      default:
        return state;
    }
  };