import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import EmployeeForm from "../../components/Employee/EmployeeForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEmployee, updateEmployee } from "../../services/Actions/employeeActions";
import { RESET_EMPLOYEE } from '../../services/Constants/employeeConstants';
import { toast } from "react-toastify";
import OverlayLoading from "../../components/UI/OverlayLoading/OverlayLoading";
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

const EditEmployee = () => {
    const small = useMediaQuery("(min-width:600px)");

    const employeeState = useSelector((state) => state.employee);
    const { employee, error, employeeUpdated, loading } = employeeState;

    const userData = useSelector((state) => state.userData);

    const initialValues = {
        name: "",
        email: "",
        phoneNumber: "+44",
        password: "",
        image: null
    };

    const handleFormSubmit = (values) => {
        delete values.user;
        values.append("id", id);
        values.append('employerId', userData.user.id);
        dispatch(updateEmployee(values));
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
  
    useEffect(() => {    
        dispatch(getEmployee(id));
    }, [dispatch, id]);

    useEffect(() => {
        if(employeeUpdated) {
          toast.success("Employee Updated Successfully");
          dispatch({ type: RESET_EMPLOYEE});
          navigate("/employees");
        }
    }, [employeeUpdated, dispatch]);

    useEffect(() => {
        if(error) {
            toast.error(error.message);
            dispatch({ type: RESET_EMPLOYEE})
        }
    }, [error, dispatch]);  

    return (
        <Layout type="Employer">
            <Box>
                <Box m="20px">
                    <PageHeader title="EDIT EMPLOYEE" subtitle="Update Employee Information" />
                    <Box display="flex">
                        <Box sx={{ flex: 1 }}>
                            { !loading && <EmployeeForm initialValues={employee ? employee : initialValues} handleFormSubmit={handleFormSubmit} btnText={"Update Employee"}/> }
                        </Box> 
                        { small && <Box sx={{ flex: 1 }}></Box>}
                    </Box>
                </Box>
                { loading && <OverlayLoading loading={loading}/> }             
            </Box>
        
        </Layout>
    );
};

export default EditEmployee;
