import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function Legend(props) {
  return (
    <>
        <Box display="flex">
            <Box
                sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: props.color,
                    '&:hover': {
                    backgroundColor: props.color
                    },
                }}
            />
            <Typography sx={{ml:"5px"}} variant="h6">{props.label}</Typography>
        </Box>
        <Box sx={{height: "5px"}}></Box>
    </>
  );
}
