import React, { useEffect } from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import EmployeeTable from "../../components/Employee/EmployeeTable";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../services/Actions/employeeActions";
import Tooltip from '@mui/material/Tooltip';

const Employees = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const userState = useSelector((state) => state.userData);
  const { user } = userState;

  const employeeState = useSelector((state) => state.employee);
  const { loading, employees, error } = employeeState;

  const dispatch = useDispatch();

  useEffect(() => {    
    if(userState && userState.user) {
      dispatch(fetchEmployees(userState.user.id));
    }
  }, [dispatch, userState, error]);


  return (
    <Layout type="Employer">
        <Box mx="20px" >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <PageHeader title="EMPLOYEES" subtitle="Managing the Employees" />
          </Box>
            <Box
              display="-webkit-box"
              justifyContent="end"
              sx={{mb: 2}}
            >
                <LoadingButton                   
                  sx={                    
                    {
                      backgroundColor: colors.greenAccent[500],
                    }
                  }

                  variant="contained"
                  onClick={
                    () => {
                        navigate("/employee/add");
                    }
                  }>
                  <GroupAddIcon sx={{ mr: "10px" }} />
                  Add Employee
                </LoadingButton>
            </Box>
            <EmployeeTable records={[...employees].reverse()} noActions={false} loading={loading}/>
        </Box>
    </Layout>
  );
};

export default Employees;
