import React, { useEffect } from 'react';
import Layout from "../../components/Layout/Layout";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PageHeader from "../../components/PageHeader/PageHeader";
import StatBox from "../../components/UI/StatBox/StatBox";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Groups2Icon from '@mui/icons-material/Groups2';
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../services/Actions/adminActions";
import useMediaQuery from '@mui/material/useMediaQuery';
import OverlayLoading from '../../components/UI/OverlayLoading/OverlayLoading';

function AdminDashboard() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const small = useMediaQuery("(max-width: 600px)");

    const adminState = useSelector((state) => state.admin);
    const { dashboardData, loading } = adminState;

    useEffect(() => {    
        dispatch(getDashboardData());
    }, []);
    
    return (
        <Layout>
            <Box m="20px">
            {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="DASHBOARD" subtitle="Welcome to your dashboard" />
                </Box>

                {/* GRID & CHARTS */}
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="140px"
                    gap="20px"
                >
                    {/* ROW 1 */}
                    <Box
                        gridColumn={small ? "span 12" : "span 4"} 
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                    <StatBox
                        title={dashboardData?.employerCount}
                        subtitle="Organizations"
                        progress="0.75"
                        increase="+14%"
                        icon={
                        <CorporateFareIcon
                            sx={{ color: colors.blueAccent[500], fontSize: "30px" }}
                        />
                        }
                    />
                    </Box>
                    <Box
                        gridColumn={small ? "span 12" : "span 4"} 
                        backgroundColor={colors.primary[400]}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                    <StatBox
                        title={dashboardData?.employerCount}
                        subtitle="Employers"
                        progress="0.50"
                        increase="+21%"
                        path="/employer"
                        icon={
                        <Groups2Icon
                            sx={{ color: colors.blueAccent[500], fontSize: "30px" }}
                        />
                        }
                    />
                    </Box>                    
                </Box>
                { loading && <OverlayLoading loading={loading}/> }             
            </Box>
        </Layout>
    );
}

export default AdminDashboard;