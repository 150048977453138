import { useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import logoLight from '../../../src/logo-light.png';
import logoDark from '../../../src/logo-dark.png';
import Groups2Icon from '@mui/icons-material/Groups2';
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import ModeSwitch from "../../components/UI/ModeSwitch/ModeSwitch"
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateThemeModePrefrence } from "../../services/Actions/userActions";
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';

const Item = ({ title, to, icon, selected, setSelected, ...otherProps }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={to === location.pathname}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        navigate(to);
      }}
      icon={icon}
      {...otherProps}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>      
    </MenuItem>
  );
};

const AdminSidebar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMedium = useMediaQuery("(max-width: 960px)");
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed);
  const { collapseSidebar } = useProSidebar();
  const dispatch = useDispatch();
  const colorMode = useContext(ColorModeContext);

  const [selected, setSelected] = useState(props.title);

  const userState = useSelector((state) => state.userData);
  const { user } = userState;


  return (
    <Box
      sx={{
        "& .ps-sidebar-container": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-menu-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-menuitem-root:hover": {
          bgColor: theme.palette.mode === "dark" ? theme.palette.grey[100] : ""
        },
        "& .ps-menu-button:hover": {
          color: "#36c147 !important",
        },
        "& .ps-menu-button.ps-active": {
          color: "#36c147 !important",
        },
        "&:hover": {
          backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[100] : ""
        }
      }}
    >
      <Sidebar 
          style={{ 
            display: props.showSidebar,
            bottom: "0",
            position: "fixed",
            top: "0",
            zIndex: 10
           }} 
          defaultCollapsed={isCollapsed} 
          collapsed={isCollapsed}
        >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            // onClick={() => { 
            //   if(isMedium) {
            //     props.handleCollapsed(false);
            //   } else {
            //     collapseSidebar();
            //     setIsCollapsed(!isCollapsed); 
            //     props.handleCollapsed(!isCollapsed);                
            //   }
            // }}
            icon={isCollapsed ?                 
              <IconButton onClick={() => {
                if(isMedium) {
                  props.handleCollapsed(false);
                } else {
                  collapseSidebar();
                  setIsCollapsed(!isCollapsed); 
                  props.handleCollapsed(!isCollapsed);                
                }
              }}>
                <MenuOutlinedIcon />
              </IconButton> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Box 
                    component="img" 
                    sx={{
                        width: "150px",
                        height: "30px"
                    }}
                    src={theme.palette.mode === "light" ? logoLight : logoDark} 
                    alt="logo" 
                    onClick={() => navigate("/admin-dashboard")}
                />
                <IconButton onClick={() => {
                    if(isMedium) {
                      props.handleCollapsed(false);
                    } else {
                      setIsCollapsed(!isCollapsed); 
                      props.handleCollapsed(!isCollapsed);                
                    }
                  }}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">              
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  sx={{ 
                    bgcolor: colors.blueAccent[500],
                    cursor: "pointer", 
                    borderRadius: "50%",
                    width: 56, height: 56
                  }}
                  onClick={
                    () => navigate('/profile')
                  }
                >{user ? user.name[0].toUpperCase() : ""}</Avatar>                
              </Box>
              <Box textAlign="center">
                <Typography 
                  variant="h5" 
                  sx={{ m: "10px 0 0 0" }}
                  color={colors.blueAccent[500]}>
                  {user?.name}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/admin-dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Manage
            </Typography>
            <Item
              title="Employer"
              to="/employers"
              icon={<Groups2Icon />}
              selected={selected}
              setSelected={setSelected}
            />            

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Settings
            </Typography>
            <Item
              title="Profile"
              to="/profile"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />            
            {/* {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
                sx={{ m: "15px 0 5px 20px" }}
              >
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                >
                  Mode
                </Typography>
                <ModeSwitch onChange={ () => {
                    dispatch(updateThemeModePrefrence(theme.palette.mode === "dark" ? "light" : "dark"));
                    colorMode.toggleColorMode();
                  }} 
                  defaultChecked={theme.palette.mode === "dark"}
                />
              </Box>
            )}                         */}
          </Box>
        </Menu>
      </Sidebar> 
    </Box>
  );
};

export default AdminSidebar;
