import { Backdrop, CircularProgress } from "@mui/material";

function OverlayLoading(props) {
  return (
    <div>
      <Backdrop open={props.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default OverlayLoading;
