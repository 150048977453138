import API from "../../middleware/api";
import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    LOGOUT_USER,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    UPDATE_USER_PREFRENCES
} from "../Constants/userConstants";

const api = new API();

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_USER_REQUEST });
    const { data } = await api.post("/login", {
      email,
      password,
    });

    let user = data.user;
    localStorage.setItem("user", JSON.stringify({...user, ...data}));
    
    dispatch({ type: LOGIN_USER_SUCCESS, payload: {...user, ...data}});
  } catch (error) {

    dispatch({
      type: LOGIN_USER_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const updatePassword = (userId, type, currentPassword, newPassword) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    await api.post("/updatePassword", {
      id: userId,
      type,
      currentPassword,
      newPassword,
    });

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const logoutUser = () => async (dispatch) => {

  if (localStorage.key("user")) {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }
  dispatch({ type: LOGOUT_USER });
};

export const updateThemeModePrefrence = (mode) => async (dispatch) => {
  try {
    const { userPrefrences } = JSON.parse(
      localStorage.getItem("user_prefrences")
        ? localStorage.getItem("user_prefrences")
        : JSON.stringify({ mode: "light" }),
    );
    const data = { ...userPrefrences, mode };
    localStorage.setItem("user_prefrences", JSON.stringify(data));
    dispatch({ type: UPDATE_USER_PREFRENCES, payload: data });
  } catch (error) {}
};
