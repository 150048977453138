import axiosInstance from "./axiosInstance";

const headers = {
  "Content-Type": "application/json",
  charset: "UTF-8",
};

class API {
  async get(endpoint) {
    return await axiosInstance.get(`${endpoint}`);
  }

  async post(endpoint, body) {
    return await axiosInstance.post(`${endpoint}`, body, headers);
  }

  async put(endpoint, body) {
    return await axiosInstance.put(`${endpoint}`, body, headers);
  }

  async delete(endpoint, body) {
    return await axiosInstance.delete(`${endpoint}`, headers);
  }
}

export default API;
