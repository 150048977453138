import React, {Fragment } from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ProSidebarProvider } from 'react-pro-sidebar';
import './App.css';
import { Provider } from "react-redux";
import store from "./services/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RolesBasedRoutes from './components/RolesBasedRoutes/RolesBasedRoutes';

function App() {
  const [theme, colorMode] = useMode();

  return (
    <Fragment>
      <Provider store={store}>
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ProSidebarProvider>
                <RolesBasedRoutes />
              </ProSidebarProvider>
              <ToastContainer theme="colored" />
            </ThemeProvider>
        </ColorModeContext.Provider>
      </Provider>
    </Fragment>
  );
}

export default App;
