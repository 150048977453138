import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, useTheme, Box } from "@mui/material";
import { tokens } from "../../theme";
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import EmployerSetting from './EmployerSetting';

export default function Settings() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const userState = useSelector((state) => state.userData);
    const { user } = userState;
    const small = useMediaQuery("(max-width: 600px)");
    const size = small ? 190 : 230;
    const userData = useSelector((state) => state.userData);

    return (
        <Card sx={{ backgroundColor: colors.primary[400] }}>                
            <CardContent>
                <Typography
                    variant="h3"                    
                    fontWeight="bold"
                    sx={{ m: "0 0 15px 0" }}
                    color={colors.blueAccent[500]}
                >
                     Settings 
                </Typography>
                <EmployerSetting />
            </CardContent>
        </Card>
    );
}