import React, { useEffect } from 'react';
import { Box } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import TimelogTable from "../../components/Timelog/TimelogTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimelogs } from "../../services/Actions/timelogActions";
import Legend from '../../components/UI/Legend/Legend';

const TimeLogs = () => {
  const userState = useSelector((state) => state.userData);

  const timelogState = useSelector((state) => state.timelog);
  const { timelogs, error, loading } = timelogState;

  const dispatch = useDispatch();

  useEffect(() => {    
    if(userState && userState.user) {
      dispatch(fetchTimelogs(userState.user.id));
    }
  }, [dispatch, userState, error]);

  return (
    <Layout type="Employer">
        <Box mx="20px" >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <PageHeader title="TIME LOGS" subtitle="Managing the Time Logs" />
            <Box>
              <Legend label="Emergency Checkin or Checkout" color="#E57373" />
              <Legend label="Late Checkin or Checkout" color="#FFFF00" />
            </Box>
          </Box>
          <TimelogTable loading={loading} records={[...timelogs].reverse()} noActions={false}/>
        </Box>
    </Layout>
  );
};

export default TimeLogs;
