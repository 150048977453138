import React from 'react';
import { Box, Grid } from "@mui/material";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import UpdatePasswordForm from "../../components/UpdatePasswordForm/UpdatePasswordForm";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import Settings from "../../components/Settings/Settings";
import { useSelector } from "react-redux";

const Profile = () => {
    const userData = useSelector((state) => state.userData);

    return (
        <Layout type={userData?.user?.type === 2 ? "Employer" : null}>
            <Box>
                <Box mx="20px">
                    <PageHeader title="Profile" subtitle="Personal Information" />
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <ProfileCard />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <UpdatePasswordForm />
                        </Grid>
                    </Grid>
                    <Box sx={{ height: "20px" }}></Box>
                    {
                        userData?.user?.type === 2 ? 
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Settings />
                            </Grid>
                            <Grid item sm={12} md={6}>
                            </Grid>
                        </Grid> :
                        <></>
                    }
                </Box>             
            </Box>       
        </Layout>
    );
};

export default Profile;
