import {
    ADD_JOB_REQUEST,
    ADD_JOB_SUCCESS,
    ADD_JOB_FAIL,
    UPDATE_JOB_REQUEST,
    UPDATE_JOB_SUCCESS,
    UPDATE_JOB_FAIL,
    DELETE_JOB_FAIL,
    DELETE_JOB_SUCCESS,
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAIL,
    FETCH_JOBS,
    RESET_JOB,
    FETCH_JOBS_SUCCESS,
    FETCH_JOBS_REQUEST
  } from "../Constants/jobConstants";
  
  const initialState = {
    jobAdded: false,
    jobUpdated: false,
    jobDeleted: false,
    jobs: [],
    job: null,
    loading: false,
    error: null
  };
  
  export const jobReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_JOB_REQUEST:
        return { ...state, loading: true };
      case ADD_JOB_SUCCESS:
        return { ...state, loading: false, jobAdded: action.payload };
      case ADD_JOB_FAIL:
        return { ...state, loading: false, error: action.payload };
      case UPDATE_JOB_REQUEST:
        return { ...state, loading: true };
      case UPDATE_JOB_SUCCESS:
        return { ...state, loading: false, jobUpdated: action.payload };
      case UPDATE_JOB_FAIL:
        return { ...state, loading: false, error: action.payload };
      case DELETE_JOB_SUCCESS:
        return { ...state, loading: false, jobDeleted: action.payload };
      case DELETE_JOB_FAIL:
        return { ...state, loading: false, error: action.payload };
      case GET_JOB_REQUEST:
        return { ...state, loading: true };
      case GET_JOB_SUCCESS:
        return { ...state, loading: false, job: action.payload };
      case GET_JOB_FAIL:
        return { ...state, loading: false, error: action.payload };
      case FETCH_JOBS_REQUEST:
        return { ...state, loading: true };
      case FETCH_JOBS_SUCCESS:
        return { ...state, loading: false, jobs: action.payload };
      case RESET_JOB:
        return initialState;      
      default:
        return state;
    }
  };