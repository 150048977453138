import {
    ADMIN_DASHBOARD_REQUEST,
    ADMIN_DASHBOARD_SUCCESS,
    ADMIN_DASHBOARD_FAIL,
    RESET_ADMIN
  } from "../Constants/adminConstants";
  
  const initialState = {
    dashboardData: {
        "employerCount": 0,
        "employeeCount": 0,
        "organizationCount": 0
    },
    loading: false,
    error: null
  };
  
  export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADMIN_DASHBOARD_REQUEST:
        return { ...state, loading: true };
      case ADMIN_DASHBOARD_SUCCESS:
        return { ...state, loading: false, dashboardData: action.payload };
      case ADMIN_DASHBOARD_FAIL:
        return { ...state, loading: false, error: action.payload };
      case RESET_ADMIN:
        return initialState;      
      default:
        return state;
    }
  };