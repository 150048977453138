import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { userReducer } from "./Reducers/userReducers";
import { employerReducer } from "./Reducers/employerReducers";
import { employeeReducer } from "./Reducers/employeeReducers";
import { adminReducer } from "./Reducers/adminReducers";
import { jobReducer } from "./Reducers/jobReducers";
import { timelogReducer } from "./Reducers/timelogReducers";

const reducer = combineReducers({
  userData: userReducer,
  employer: employerReducer,
  admin: adminReducer,
  employee: employeeReducer,
  jobData: jobReducer,
  timelog: timelogReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
