import React, { useEffect } from 'react';
import { Box, useTheme, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import { formatTime } from "../../Utils";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import { CustomNoDataAvailableOverlay } from '../UI/CustomNoDataAvailableOverlay/CustomNoDataAvailableOverlay';
import { TableLoading } from '../UI/TableLoading/TableLoading';
import TextField from '@mui/material/TextField';

export const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--emergency-checkout': {
    backgroundColor: "#E57373",
    '&:hover': {
      backgroundColor: "#E57373",
    },
    '&.Mui-selected': {
      backgroundColor: "#E57373",
      '&:hover': {
        backgroundColor: "#E57373",
      },
    },
  },
  '& .super-app-theme--late-checkin-checkout': {
    backgroundColor: "#FFFF00",
    '&:hover': {
      backgroundColor: "#FFFF00",
    },
    '&.Mui-selected': {
      backgroundColor: "#FFFF00",
      '&:hover': {
        backgroundColor: "#FFFF00",
      },
    },
  },
  '& .super-app-theme--normal': {
    backgroundColor: theme.palette.primary,
  },
}));

const LogsTable = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const timelogState = useSelector((state) => state.timelog);
    const { updatedStatus } = timelogState;

    const dispatch = useDispatch();

    useEffect(() => {    
        if(updatedStatus) {
            toast.success("Updated Status Successfully");
            setTimeout(() => window.location.reload(), 800);
        } 
    }, [dispatch, updatedStatus]);

    useEffect(() => {
        props.setTotalHours(getTotalHours(props.records));
    }, [props.records]);

  
    const columns = [
        {
            field: "jobName",
            headerName: "Job Name",
            flex: 1,
            valueGetter: (params) => params.row.job.jobName
        },
        {
            field: "checkInDate",
            type: "date",
            headerName: "Actual Checkin Date",
            flex: 1,
            valueGetter: (params) => new Date(params.row.logs.checkInDate)
        },
        {
            field: "checkIn",
            headerName: "Actual Checkin Time",
            flex: 1,

            valueGetter: (params) => params.row.logs.checkIn
        },
         {
            field: "checkOut",
            headerName: "Actual Checkout Time",
            flex: 1,
            valueGetter: (params) => params.row.logs.checkOut
        },
        {
            field: "overtime",
            headerName: "OverTime",
            flex: 1,
            valueGetter: (params) => formatTime(params.row.logs.overtime)
        },
        {
            field: "addOvertime",
            headerName: "Include Overtime",
            type: "action",
            flex: 1,
            renderCell: (params) => {
                return (
                params.row.logs.overtime !== "" ? 
                <Checkbox  
                    checked={params.row.logs.approvedOvertime}
                    disabled={(params.row.logs.overtime == null || params.row.logs.overtime == "0:0") || (params.row.saved ? params.row.logs.status !== "Pending" :  false)}
                    onChange={(e) => {
                    const updatedRows = [...params.api.getRowModels()];
                    const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

                    if (rowIndex !== -1) {
                        updatedRows[rowIndex][1].logs.approvedOvertime = e.target.checked;
                        updatedRows[rowIndex][1].isErrorInReason = (params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                                                                    (params.row.logs.status === "Approve" && 
                                                                    (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && 
                                                                    !params.row.logs.approvedOvertime) && (!params.row.logs.reason));
                    }
                    
                    const rows = updatedRows.map((row) => ({
                        "id": row[0],
                        ...row[1],
                    }));

                    props.setTotalHours(getTotalHours(props.records));
                    props.setEnableSubmitBtn(isCompleted(rows));  
                    props.setLogsData(rows);                
                    // Update the DataGrid with the modified rows
                    params.api.updateRows(rows);

                    
                    }}
                /> : 
                <></>
                )
            }
        },
        {
            field: "status",
            headerName: "Status",
            type: "action",
            flex: 1,
            renderCell: (params) => {
                return (
                <Box sx={{ minWidth: 100 }}>
                    <FormControl variant="standard" disabled={params.row.saved ? params.row.logs.status !== "Pending" :  false}>
                    <Select
                        value={params.row.logs.status}
                        label="Status"
                        onChange={(e) => {
                        const updatedRows = [...params.api.getRowModels()];
                        const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

                        if (rowIndex !== -1) {
                            updatedRows[rowIndex][1].logs.status = e.target.value;
                            updatedRows[rowIndex][1].isErrorInReason = (params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                                                                        (params.row.logs.status === "Approve" && 
                                                                        (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && 
                                                                        !params.row.logs.approvedOvertime) && (!params.row.logs.reason));
                        }
                        
                        const rows = updatedRows.map((row) => ({
                            "id": row[0],
                            ...row[1]
                        }));

                        props.setTotalHours(getTotalHours(props.records));
                        props.setEnableSubmitBtn(isCompleted(rows));
                        props.setLogsData(rows);                
                        // Update the DataGrid with the modified rows
                        params.api.updateRows(rows);
                        
                        }}
                    >
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Approve">Approve</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                    </FormControl>
                </Box>
                )
            }
        },
        {
            field: 'reason',
            headerName: 'Reason for Rejected Shift / Overtime',
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return params.row.saved ?
                    <Typography>{params.row.logs.reason}</Typography>            
                : <TextField 
                    // disabled={params.row.logs.status !== "Pending"}
                    required={params.row.logs.status === "Rejected"}
                    id="standard"
                    name="reason" 
                    error={(params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                        (params.row.logs.status === "Approve" && (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && !params.row.logs.approvedOvertime && !params.row.logs.reason)) }
                    onChange={(e) => {
                        const updatedRows = [...params.api.getRowModels()];
                        const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

                        if (rowIndex !== -1) {
                        updatedRows[rowIndex][1].logs.reason = e.target.value;
                        updatedRows[rowIndex][1].isErrorInReason = (params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                                                                    (params.row.logs.status === "Approve" && 
                                                                    (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && 
                                                                        !params.row.logs.approvedOvertime) && (!params.row.logs.reason));
                        }
                    
                        const rows = updatedRows.map((row) => ({
                            "id": row[0],
                            ...row[1]
                        }));

                        props.setEnableSubmitBtn(isCompleted(rows));
                        // Update the DataGrid with the modified rows
                        props.setLogsData(rows);                
                        params.api.updateRows(rows);
                    
                    }}
                    variant="standard" 
                    multiline
                />;
            },
        }
    ];

    const [filterModel, setFilterModel] = React.useState({items: []});

    const handleFilterModelChange = (model) => {
        setFilterModel(model);
    };

    const getTotalHours = (rows) => {
        let total = 0;
        rows.forEach((record) => {
            if(
                record.logs.checkOut != null && 
                record.logs.status == 'Approve') {
                
                total = total + 1;

                // let clockIn = new Date(record.logs.checkInDate);
                // const checkinTime = record.logs.checkIn.split(":");
                // clockIn.setHours(parseInt(checkinTime[0]), parseInt(checkinTime[1]));
        
                // let clockOut = new Date(record.logs.checkOutDate);
                // const checkoutTime = record.logs.checkOut.split(":");
                // clockOut.setHours(parseInt(checkoutTime[0]), parseInt(checkoutTime[1]));        

                // let remainingTime = detuctMinutesFromTime(getTimeDiffInHoursFromDates(clockIn, clockOut), record.job.breakTime);

                // if(record.logs.approvedOvertime && record.logs.overtime) {
                //     // Create Date objects for the two times
                //     const minutes1 = timeToMinutes(remainingTime);
                //     const minutes2 = timeToMinutes(record.logs.overtime);
                    
                //     total = total + (minutes1 + minutes2);
                // } else {
                //     total = total + timeToMinutes(remainingTime);
                // }
            }
        });

        return total;
        // return minutesToTime(total);
    }

    const isCompleted = (rows) => {
        let complete = true;
        rows.forEach((record) => {
            if(record.logs.status === "Pending" || record.isErrorInReason) {
                complete = false;
            }
        });

        return complete;
    }
    //props.records[0].binary
    const binayColumns = [
        {
            field: "jobName",
            headerName: "Job Name",
            flex: 1,
            valueGetter: (params) => params.row.job.jobName
        },
        {
            field: "checkInDate",
            type: "date",
            headerName: "Actual Checkin Date",
            flex: 1,
            valueGetter: (params) => new Date(params.row.logs.checkInDate)
        },
        {
            field: "overtime",
            headerName: "OverTime",
            flex: 1,
            valueGetter: (params) => params.row.logs.overtime
        },
        {
            field: "addOvertime",
            headerName: "Include Overtime",
            type: "action",
            flex: 1,
            renderCell: (params) => {
                return (
                params.row.logs.overtime !== "" ? 
                <Checkbox  
                    checked={params.row.logs.approvedOvertime}
                    disabled={(params.row.logs.overtime == null || params.row.logs.overtime == "0:0") || (params.row.saved ? params.row.logs.status !== "Pending" :  false)}
                    onChange={(e) => {
                    const updatedRows = [...params.api.getRowModels()];
                    const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

                    if (rowIndex !== -1) {
                        updatedRows[rowIndex][1].logs.approvedOvertime = e.target.checked;
                        updatedRows[rowIndex][1].isErrorInReason = (params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                                                                    (params.row.logs.status === "Approve" && 
                                                                    (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && 
                                                                    !params.row.logs.approvedOvertime) && (!params.row.logs.reason));
                    }
                    
                    const rows = updatedRows.map((row) => ({
                        "id": row[0],
                        ...row[1],
                    }));

                    props.setTotalHours(getTotalHours(props.records));
                    props.setEnableSubmitBtn(isCompleted(rows));  
                    props.setLogsData(rows);                
                    // Update the DataGrid with the modified rows
                    params.api.updateRows(rows);


                    
                    }}
                /> : 
                <></>
                )
            }
        },
         {
            
            field: "unit",
            headerName: "Unit",
            flex: 1,
            valueGetter: (params) => {
                console.log(params.row.logs.checkInDate);
                console.log(params.row.logs.checkOut);
                if(params.row.logs.checkOut != null) {
                    return 1;
                }
                return 0;
            }
        },
        {
            field: "status",
            headerName: "Status",
            type: "action",
            flex: 1,
            renderCell: (params) => {
                return (
                <Box sx={{ minWidth: 100 }}>
                    <FormControl variant="standard" disabled={params.row.saved ? params.row.logs.status !== "Pending" :  false}>
                    <Select
                        value={params.row.logs.status}
                        label="Status"
                        onChange={(e) => {
                        const updatedRows = [...params.api.getRowModels()];
                        const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

                        if (rowIndex !== -1) {
                            updatedRows[rowIndex][1].logs.status = e.target.value;
                            updatedRows[rowIndex][1].isErrorInReason = (params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                                                                        (params.row.logs.status === "Approve" && 
                                                                        (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && 
                                                                        !params.row.logs.approvedOvertime) && (!params.row.logs.reason));
                        }
                        
                        const rows = updatedRows.map((row) => ({
                            "id": row[0],
                            ...row[1]
                        }));

                        props.setTotalHours(getTotalHours(props.records));
                        props.setEnableSubmitBtn(isCompleted(rows));
                        props.setLogsData(rows);                
                        // Update the DataGrid with the modified rows
                        params.api.updateRows(rows);
                        
                        }}
                    >
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Approve">Approve</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                    </FormControl>
                </Box>
                )
            }
        },
        {
            field: 'reason',
            headerName: 'Reason for Rejected Shift / Overtime',
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return params.row.saved ?
                    <Typography>{params.row.logs.reason}</Typography>            
                : <TextField 
                    // disabled={params.row.logs.status !== "Pending"}
                    required={params.row.logs.status === "Rejected"}
                    id="standard"
                    name="reason" 
                    error={(params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                        (params.row.logs.status === "Approve" && (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && !params.row.logs.approvedOvertime && !params.row.logs.reason)) }
                    onChange={(e) => {
                        const updatedRows = [...params.api.getRowModels()];
                        const rowIndex = updatedRows.findIndex(row => row[0] === params.row.id);

                        if (rowIndex !== -1) {
                        updatedRows[rowIndex][1].logs.reason = e.target.value;
                        updatedRows[rowIndex][1].isErrorInReason = (params.row.logs.status === "Rejected" && (!params.row.logs.reason)) ||
                                                                    (params.row.logs.status === "Approve" && 
                                                                    (params.row.logs.overtime !== null && params.row.logs.overtime !== "0:0" && 
                                                                        !params.row.logs.approvedOvertime) && (!params.row.logs.reason));
                        }
                    
                        const rows = updatedRows.map((row) => ({
                            "id": row[0],
                            ...row[1]
                        }));

                        props.setEnableSubmitBtn(isCompleted(rows));
                        // Update the DataGrid with the modified rows
                        props.setLogsData(rows);                
                        params.api.updateRows(rows);
                    
                    }}
                    variant="standard" 
                    multiline
                />;
            },
        }
    ];
    return (
        <Box
            id="timelog-table"
            height="65vh"
            sx={{
                "& .name-column--cell": {
                color: colors.blueAccent[300],
                },
                "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
                }
            }}
        >
        {props.records.length === 0 && !props.loading && <Typography variant="h5" color={colors.redAccent[500]}>No Logs Found</Typography>}
         {props.records.length !=0 && <StyledDataGridPro 
            rows={props.records}
            loading={props.loading}
            slots={{
                toolbar: GridToolbar,
                noRowsOverlay:CustomNoDataAvailableOverlay,
                loadingOverlay: TableLoading
            }}
            initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
            columns: {
                columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                checkInFeedback: false,
                emergencyCheckInFeedback: false,
                checkOutFeedback: false,
                emergencyCheckOutFeedback: false,
                },
            },
            }}      
            columns={props.records[0].binary ? binayColumns :  columns}
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
        />}
        </Box>
    );
};

export default LogsTable;
