import API from "../../middleware/api";
import {
    UPDATE_STATUS_REQUEST,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_FAIL,
    UPDATE_LOGS_REQUEST,
    UPDATE_LOGS_SUCCESS,
    UPDATE_LOGS_FAIL,
    FETCH_TIMELOGS_REQUEST,
    FETCH_TIMELOGS_SUCCESS,
    FETCH_LOGS_REQUEST,
    FETCH_LOGS_SUCCESS,
    FETCH_LOGS_FAIL
} from "../Constants/timelogConstants";

const api = new API();

export const updateLogs = (logs) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LOGS_REQUEST });
    await api.post("/update/logs/status", logs);

    dispatch({ type: UPDATE_LOGS_SUCCESS, payload: true });
  } catch (error) {

    dispatch({
      type: UPDATE_LOGS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

export const updateLogStatus = (logId, status, approvedOvertime) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_STATUS_REQUEST });
      await api.post("/update/status", {
        "logId": logId,
        "status": status,
        "approvedOvertime": approvedOvertime    
      });
  
      dispatch({ type: UPDATE_STATUS_SUCCESS, payload: true });
    } catch (error) {
  
      dispatch({
        type: UPDATE_STATUS_FAIL,
        payload: error.response && error.response.data ? error.response.data : error,
      });
    }
  };

export const fetchTimelogs = (employerId) => async (dispatch) => {
    dispatch({ type: FETCH_TIMELOGS_REQUEST });    
    const { data } = await api.get(`/logs/${employerId}`);
    const logsData = data.map((log) => ({...log, "saved": log.logs.status !== "Pending"}));
    dispatch({ type: FETCH_TIMELOGS_SUCCESS, payload: logsData });    
};

export const fetchLogs = (jobId, startDate, endDate) => async (dispatch) => {
  try {

    dispatch({ type: FETCH_LOGS_REQUEST });    
    const { data } = await api.get(`/weeklyLogs/job?jobId=${jobId}&startingDate=${startDate}&endingDate=${endDate}`);
    const logsData = data.map((log) => ({...log, "saved": log.logs.status !== "Pending", "isErrorInReason": false}));
    dispatch({ type: FETCH_LOGS_SUCCESS, payload: logsData });      
  } catch (error) {  

    dispatch({
      type: FETCH_LOGS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error,
    });
  }
};

