import React, { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from "yup";
import { Formik } from 'formik';
import LoadingButton from "@mui/lab/LoadingButton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, Typography, useTheme, TextField } from "@mui/material";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, updatePassword } from "../../services/Actions/userActions";
import { RESET_USER } from '../../services/Constants/userConstants';
import { toast } from "react-toastify";
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UpdatePasswordForm = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();

    const checkoutSchema = yup.object().shape({
        currentpassword: yup.string().required('Please enter the current password'),
        newpassword: yup.string()
            .required('Please enter the new password')
            .min(8, 'The password must contain 8 characters including an upper case letter, a number and a special character.')
            .matches(/[A-Za-z]/, 'The password must contain 8 characters including an upper case letter, a number and a special character')
            .matches(/\d/, 'The password must contain 8 characters including an upper case letter, a number and a special character')
            .matches(/[!@#$%^&*?_\-]/, 'The password must contain 8 characters including an upper case letter, a number and a special character'),
        confirmpassword: yup.string().required('Please enter the confirm password').oneOf([yup.ref('newpassword'), null], 'Please make sure both passwords match')
    });

    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);

    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);

    const [showNewPassword, setShowNewPassword] = React.useState(false);

    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const initialValues = {
        currentpassword: "",
        newpassword: "",
        confirmpassword: ""
    };

    const userState = useSelector((state) => state.userData);
    const { loading, user, passwordUpdate, error } = userState;

    const handleFormSubmit = (values) => {
        console.log(user);
        dispatch(updatePassword(user.id, user.type, values.currentpassword, values.newpassword));
    };

    useEffect(() => {
        if(passwordUpdate) {
            toast.success("Password updated successfully. Redirecting...Please login again!");
            dispatch({ type: RESET_USER});
            dispatch(logoutUser());
        }
    }, [passwordUpdate, dispatch]);

    useEffect(() => {
        if(error) {
            toast.error(error.message);
            dispatch({ type: RESET_USER});
        }
    }, [error, dispatch]);
    
    return(
        <Card sx={{ backgroundColor: colors.primary[400] }}>                
            <CardContent>
                <Typography
                    variant="h3"                    
                    fontWeight="bold"
                    sx={{ m: "0 0 15px 0" }}
                    color={colors.blueAccent[500]}
                >
                    Update Password
                </Typography>
                <Formik
                    onSubmit={(values) => {
                        handleFormSubmit(values);
                    }}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                    }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="25px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                        <FormControl 
                            variant="filled" 
                            sx={{ gridColumn: "span 4" }} 
                            error={!!touched.currentpassword && !!errors.currentpassword}
                        >
                        <InputLabel htmlFor="current-password">Current Password</InputLabel>
                        <FilledInput
                            id="current-password"
                            label="Old Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={showCurrentPassword ? 'text' : 'password'}
                            value={values.currentpassword}
                            name="currentpassword"   
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCurrentPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                        <FormHelperText>{touched.currentpassword && errors.currentpassword}</FormHelperText>
                        </FormControl>   
                        <FormControl 
                            variant="filled" 
                            sx={{ gridColumn: "span 4" }} 
                            error={!!touched.newpassword && !!errors.newpassword}
                        >
                        <InputLabel htmlFor="new-password">New Password</InputLabel>
                            <FilledInput
                                id="new-password"
                                label="New Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showNewPassword ? 'text' : 'password'}
                                value={values.newpassword}
                                name="newpassword"   
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            <FormHelperText>{touched.newpassword && errors.newpassword}</FormHelperText>
                        </FormControl>   
                        <FormControl 
                            variant="filled" 
                            sx={{ gridColumn: "span 4" }} 
                            error={!!touched.confirmpassword && !!errors.confirmpassword}
                        >
                            <InputLabel htmlFor="current-password">Confirm Password</InputLabel>
                            <FilledInput
                                id="confirm-password"
                                label="Confirm New Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={values.confirmpassword}
                                name="confirmpassword"   
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                            <FormHelperText>{touched.confirmpassword && errors.confirmpassword}</FormHelperText>
                        </FormControl>  
                        {/* <TextField
                            fullWidth
                            variant="filled"
                            type="password"
                            label="Current Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.currentpassword}
                            name="currentpassword"
                            error={!!touched.currentpassword && !!errors.currentpassword}
                            helperText={touched.currentpassword && errors.currentpassword}
                            sx={{ gridColumn: "span 4" }}
                        />                  
                        <TextField
                            fullWidth
                            variant="filled"
                            type="password"
                            label="New Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.newpassword}
                            name="newpassword"
                            error={!!touched.newpassword && !!errors.newpassword}
                            helperText={touched.newpassword && errors.newpassword}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="password"
                            label="Confirm Password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.confirmpassword}
                            name="confirmpassword"
                            error={!!touched.confirmpassword && !!errors.confirmpassword}
                            helperText={touched.confirmpassword && errors.confirmpassword}
                            sx={{ gridColumn: "span 4" }}
                        />                           */}
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <LoadingButton type="submit" loading={loading} color="secondary" variant="contained">
                                Update Password
                            </LoadingButton>
                        </Box>
                    </form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
}

export default UpdatePasswordForm;
