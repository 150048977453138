import { 
    Grid,
    Box,
    Typography,
    Card,
    useTheme
} from '@mui/material';
import LogoBackground from '../../components/LogoBackground/LogoBackground';
import logoLight from '../../../src/logo-light.png';
import logoDark from '../../../src/logo-dark.png';
import LoginForm from '../../components/LoginForm/LoginForm';
import Footer from '../../components/Footer/Footer';

function Login() {
    const theme = useTheme();

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <LogoBackground />
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: '85vh'
                }}
            >
                <Grid item xs={12} sx={{ml: 3, mt: 2}}>                    
                    <Box 
                        component="img" 
                        sx={{
                            width: "230px",
                            height: "40px"
                        }}
                        src={theme.palette.mode === "light" ? logoLight : logoDark} 
                        alt="logo" 
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: { xs: '91vh', md: '91vh' } }}
                    >
                        <Grid item>
                            <Card 
                                elevation={8} 
                                sx={{
                                    p: 5,
                                    maxWidth: { xs: 350, lg: 425 },
                                    margin: { xs: 2.5, md: 3 },
                                    '& > *': {
                                        flexGrow: 1,
                                        flexBasis: '50%'
                                    },
                                }} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" sx={{ fontWeight: "600" }}>LOGIN</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoginForm />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Box>
    );
}

export default Login;