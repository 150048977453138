
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import FilledInput from '@mui/material/FilledInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultiSelect(props) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const val = typeof value === 'string' ? value.split(',') : value;
    setPersonName(val);
    props.setValue(props.name, val);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%", mb: 2 }} variant="filled" error={props.isError}>
        <InputLabel id="demo-simple-select-filled-label">{props.label}</InputLabel>
        <Select
          variant="filled"
          multiple
          displayEmpty
          input={<FilledInput id="select-multiple-chip" label="Chip" />}
          name={props.name}
          value={props.value}
          onChange={handleChange}
          onBlur={handleChange}
          renderValue={(selected) => {          
            return selected.join(', ');
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em></em>
          </MenuItem>
          {props.options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.children}</FormHelperText>
      </FormControl>
    </div>
  );
}

