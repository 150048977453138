import AdminDashboard from "../../pages/AdminDashboard/AdminDashboard";
import EmployerDashboard from "../../pages/EmployerDashboard/EmployerDashboard";
import Employers from "../../pages/Employer/Employers";
import AddEmployer from "../../pages/Employer/AddEmployer";
import AddEmployee from "../../pages/Employee/AddEmployee";
import EditEmployer from "../../pages/Employer/EditEmployer";
import EditEmployee from "../../pages/Employee/EditEmployee";
import Employees from "../../pages/Employee/Employees";
import Jobs from "../../pages/Jobs/Jobs";
import AddJob from "../../pages/Jobs/AddJob";
import EditJob from "../../pages/Jobs/EditJob";
import Profile from '../../pages/Profile/Profile';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from '../../pages/Login/Login';
import { useNavigate } from "react-router-dom";
import TimeLogs from "../../pages/TimeLogs/TimeLogs";
import Logs from "../../pages/Logs/logs";

const Admin = (props) => {
    const navigate = useNavigate();

    const userState = useSelector((state) => state.userData);
    const { user } = userState;  

    if (user === null) {
        window.location.href = "/";
        return null;
      }

    return (user && user.type === 1) ? <>{props.children}</> : <>You don't have access to this page.</>
};

const Employer = (props) => {
    const navigate = useNavigate();

    const userState = useSelector((state) => state.userData);
    const { user } = userState;  

    if (user === null) {
        window.location.href = "/";
        return null;
      }

    return (user && user.type === 2) ? <>{props.children}</> : <>You don't have access to this page.</>
};

export default function RolesBasedRoutes() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/logs/job/:jobId/start/:startingDate/end/:endingDate" element={<Logs />} />
                <Route path="/profile" element={<Profile />} />                    
                
                <Route path="/admin-dashboard" element={<Admin> <AdminDashboard /> </Admin>} />
                <Route path="/employers" element={<Admin> <Employers /> </Admin>} />          
                <Route path="/employer/add" element={<Admin> <AddEmployer /> </Admin>} />    
                <Route path="/employer/update/:id" element={<Admin> <EditEmployer /> </Admin>} />          

                <Route path="/dashboard" element={<Employer> <EmployerDashboard /> </Employer>} />
                <Route path="/employees" element={<Employer> <Employees /> </Employer>} />
                <Route path="/employee/add" element={<Employer> <AddEmployee /> </Employer>} />    
                <Route path="/employee/update/:id" element={<Employer> <EditEmployee /> </Employer>} />          
                <Route path="/jobs" element={<Employer> <Jobs /> </Employer>} />
                <Route path="/job/add" element={<Employer> <AddJob /> </Employer>} />    
                <Route path="/job/update/:id" element={<Employer> <EditJob /> </Employer>} /> 
                <Route path="/timelogs" element={<Employer> <TimeLogs /> </Employer>} />

            </Routes>
        </Router>            
    );
}