import React, { useEffect } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import logoLight from '../../../src/logo-light.png';
import LoadingButton from "@mui/lab/LoadingButton";
import LogsTable from "../../components/LogsTable/LogsTable";
import { tokens } from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogs, updateLogs } from "../../services/Actions/timelogActions";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RESET_TIMELOGS } from '../../services/Constants/timelogConstants';
import { getSimpleFormatDate } from '../../Utils';
import Legend from '../../components/UI/Legend/Legend';

const Logs = () => {
    const timelogState = useSelector((state) => state.timelog);
    const { updatedLogs, logs, error, loading } = timelogState;

    const [isReadOnly, setIsReadOnly] = React.useState(false);

    const dispatch = useDispatch();

    const { jobId, startingDate, endingDate } = useParams();

    const isAllRecordsSaved = (rows) => {
        let isRecordunsafed = false;
        rows.forEach((record) => {
            if(!record.saved) {
                isRecordunsafed = true;
            } 
        });
        return isRecordunsafed;
    }

    useEffect(() => {
        setIsReadOnly(!isAllRecordsSaved(logs));
    }, [logs]);

    useEffect(() => {    
        if(updatedLogs) {
            toast.success("Report Submitted Successfully");
            setTimeout(() => window.location.reload(), 500);
        } 
    }, [dispatch, updatedLogs]);
      
    useEffect(() => {    
        dispatch(fetchLogs(jobId, startingDate, endingDate));
    }, [dispatch, jobId, startingDate, endingDate]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [totalHours, setTotalHours] = React.useState("");
    const [enableSubmitBtn, setEnableSubmitBtn] = React.useState(false);
    const [logsData, setLogsData] = React.useState([]);
    const [binary, setBinary] = React.useState(false);

    useEffect(() => {
        if(error) {
            toast.error(error.message);
            dispatch({ type: RESET_TIMELOGS});
        }
    }, [error, dispatch]);

    return (
        <Box sx={{ backgroundColor: "#f2f0f0" }}>
            <Box mx="30px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box 
                        component="img" 
                        sx={{
                            width: "15%",
                            height: "20%"
                        }}
                        src={logoLight} 
                        alt="logo" 
                    />
                    <Box mt="30px"
                        align="center"
                        sx={{
                            width: "40%"
                        }}
                    >
                        <Typography
                            variant="h3"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            align="center"
                            sx={{ m: "0 0 5px 0" }}
                        >
                            Weekly Time logging Report
                        </Typography>
                        <Typography variant="h5" color={colors.blueAccent[500]} align="center">
                            {getSimpleFormatDate(startingDate)} - {getSimpleFormatDate(endingDate)}
                        </Typography>
                    </Box>
                    <Box m="30px"
                        component="img" 
                        sx={{
                            width: "10%",
                            height: "20%"
                        }}
                        src="https://meernmeer.com/wp-content/uploads/2023/05/mnm-logo.png" 
                        alt="logo" 
                    />
                </Box>
                <Box mt="30px">
                    <Box 
                        component="span"
                        sx={{ display: 'flex'}}
                    >
                        <Typography variant="h4" fontWeight="bold" sx={{ "mr": 1 }}>
                            Organization Name: 
                        </Typography>
                        <Typography variant="h4" color={colors.blueAccent[500]}>
                            {logs.length > 0 ? logs[0].organizationName : ""}
                        </Typography>
                    </Box>
                    <Box 
                        component="span"
                        sx={{ display: 'flex'}}
                    >
                        <Typography variant="h4" fontWeight="bold" sx={{ "mr": 1 }}>
                            Employee Name: 
                        </Typography>
                        <Typography variant="h4" color={colors.blueAccent[500]}>
                            {logs.length > 0 ? logs[0].employee.user.name : ""}
                        </Typography>
                    </Box>
                    <Box 
                        component="span"
                        sx={{ display: 'flex'}}
                    >
                        <Typography variant="h4" fontWeight="bold" sx={{ "mr": 1 }}>
                            Line Manager: 
                        </Typography>
                        <Typography variant="h4" color={colors.blueAccent[500]}>
                            {logs.length > 0 ? logs[0].job.lineManager : ""}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h3" fontWeight="bold" sx={{ lineHeight: 3 }}>
                        Time Logging Details
                    </Typography>
                    {
                        !isReadOnly &&
                        <Box>
                            <LoadingButton 
                                type="submit" 
                                loading={loading} 
                                color="secondary" 
                                variant="contained" 
                                disabled={!enableSubmitBtn}
                                onClick={() => {
                                    let data = logsData.map((record) => {
                                        return {
                                            "logId": record.logs.id,
                                            "status" : record.logs.status,
                                            "approvedOvertime" : record.logs.approvedOvertime,
                                            "reason" : record.logs.reason
                                        }
                                    });
                                    dispatch(updateLogs(data));
                                }}
                            >
                                Submit Report
                            </LoadingButton>
                        </Box>
                    }
                </Box>
                <LogsTable loading={loading} records={[...logs].reverse()} noActions={false} setTotalHours={setTotalHours} setEnableSubmitBtn={setEnableSubmitBtn} setLogsData={setLogsData} setBinary={setBinary}/>
                {
                    !isReadOnly &&
                    <Typography variant="h6" color={colors.redAccent[500]}>
                        *If you need any more information, you can contact their respective employers.
                    </Typography>
                }

                <Box
                    sx={{ display: 'flex', justifyContent: "space-between", mt: "10px" }}  
                >
                    <Box
                        component="span"
                        sx={{ display: 'flex'}}
                    >
                        <Typography variant="h4" fontWeight="bold" sx={{ "mr": 1 }}>
                            {
                                logs && logs[0] && logs[0].binary ? "Total Worked Units: " : "Total Worked Hours: "
                            }
                        </Typography>
                        <Typography variant="h4" color={colors.blueAccent[500]}>
                            {totalHours}
                        </Typography>
                    </Box>
                    
                </Box>
                {
                    !isReadOnly &&
                    <Box
                        sx={{
                            mt: "3px"
                        }}
                    >
                        <Typography variant="h6" color={colors.redAccent[500]}>
                            Note: Once you have submit this report the changes will be made and you will not be able to edit this page.
                        </Typography>
                    </Box>
                }
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "20px",
                        pb: "10px"
                    }}
                >
                    <Typography variant="h5" fontWeight="bold">
                        All rights reserved @MMTracker 2023, powered by Meer & Meer LLC
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Logs;
