import { useState, useEffect } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { useTheme } from "@mui/material";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useCombobox } from 'downshift';
import { InputLabel } from '@mui/material';
import {
  Input,
  Paper,
  MenuItem,
} from "@mui/material";

export default function SelectPlaceFromMap(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  
  return <Map lng={props.lng} lat={props.lat} setLng={props.setLng} setLat={props.setLat}/>;
}

function Map(props) {
    const [selected, setSelected] = useState(props.lng && props.lat ? { lat: parseFloat(props.lat), lng: parseFloat(props.lng) } : null);
    const theme = useTheme();

    useEffect(() => {
      if (props.lat === "" && props.lng === "" && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            props.setLng(longitude);
            props.setLat(latitude);
            setSelected({ lat: latitude, lng: longitude });
          },
          error => {
            console.error(error);
          }
        );
      } else {
        setSelected({ lat: props.lat, lng: props.lng });
      }
    }, [props.lat, props.lng]);

    return (
      <>
        <div className="places-container">
          <PlacesAutocomplete setSelected={setSelected} setLng={props.setLng} setLat={props.setLat} />
        </div>

        <GoogleMap
          zoom={15}
          center={selected}
          mapContainerClassName="map-container"
          onClick={(e) => {
            setSelected(e.latLng);
            props.setLng(e.latLng.lng());
            props.setLat(e.latLng.lat());
          }}
          options={
            {
                styles: theme.palette.mode === "dark" ? [
                  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                  {
                    featureType: "administrative.locality",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }]
                  },
                  {
                    featureType: "poi",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }]
                  },
                  {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#263c3f" }]
                  },
                  {
                    featureType: "poi.park",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#6b9a76" }]
                  },
                  {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [{ color: "#38414e" }]
                  },
                  {
                    featureType: "road",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#212a37" }]
                  },
                  {
                    featureType: "road",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#9ca5b3" }]
                  },
                  {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{ color: "#746855" }]
                  },
                  {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#1f2835" }]
                  },
                  {
                    featureType: "road.highway",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#f3d19c" }]
                  },
                  {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#2f3948" }]
                  },
                  {
                    featureType: "transit.station",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }]
                  },
                  {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#17263c" }]
                  },
                  {
                    featureType: "water",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#515c6d" }]
                  },
                  {
                    featureType: "water",
                    elementType: "labels.text.stroke",
                    stylers: [{ color: "#17263c" }]
                  }
                ] : []
              }
          }
        >
          {selected && <Marker position={selected} />}
        </GoogleMap>
      </>
    );
}

const PlacesAutocomplete = ({ setSelected, setLng, setLat }) => {
  const theme = useTheme();
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address});
    const { lat, lng } = await getLatLng(results[0]);

    setLng(lng);
    setLat(lat);
    setSelected({ lat, lng });
  };

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    isOpen,
    highlightedIndex,
  } = useCombobox({
    items: data.map(({ place_id, description }) => description),
    onInputValueChange: ({ inputValue }) => {
      setValue(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      handleSelect(selectedItem);
    },
  });

  const customStyles = {
    input: {
      color: theme.palette.text.primary,
      width: "100%"
    },
  };

  return (
    <div className="address-searchbox">
      <InputLabel htmlFor="autocomplete-input">Search an address</InputLabel>
      <Input
        {...getInputProps()}
        id="autocomplete-input"
        disabled={!ready}
        style={customStyles.input }
      />
      <Paper {...getMenuProps()} square>
        {isOpen &&
          data.map((item, index) => {
              return(
                <MenuItem
                  {...getItemProps({ item, index })}
                  key={`${item.description}${index}`}
                  selected={highlightedIndex === index}
                >
                  {item.description}
                </MenuItem>);
            }
          )}
      </Paper>
    </div>
  );
};