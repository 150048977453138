import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import JobTable from "../../components/Job/JobTable";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

const Jobs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Layout type="Employer">
        <Box mx="20px" >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <PageHeader title="JOBS" subtitle="Managing the Jobs" />
          </Box>
            <Box
              display="flex"
              justifyContent="end"
              sx={{mb: 2}}
            >
              <LoadingButton 
                sx={{
                  backgroundColor: colors.greenAccent[500]
                }} 
                // color="secondary" 
                variant="contained"
                onClick={
                  () => {
                    navigate("/job/add");
                  }
                }>
                <GroupAddIcon sx={{ mr: "10px" }} />
                Add Job
              </LoadingButton>
            </Box>
            <JobTable noActions={false}/>
        </Box>
    </Layout>
  );
};

export default Jobs;
