import React, {useEffect} from 'react';
import {
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from '../../services/Actions/userActions';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RESET_USER } from '../../services/Constants/userConstants';

const LoginForm = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userState = useSelector((state) => state.userData);
    const { loading, user, error } = userState;

    useEffect(() => {
        if(user !== null) {
            if(user.type === 1) {
                navigate("/admin-dashboard");
            } else {
                navigate("/dashboard");
            }
        }
    }, [user, navigate]);

    useEffect(() => {
        if(error) {
            toast.error(error.message);
            dispatch({ type: RESET_USER})
        }
    }, [error, dispatch]);

    return (
        <>
            <Formik
                initialValues={{
                    userEmail: '',
                    userPassword: ''                
                }}
                validationSchema={Yup.object().shape({
                    userEmail: Yup.string().email('Please enter a valid email').max(255).required('Please enter the email'),
                    userPassword: Yup.string().max(255).required('Please enter the password')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setStatus({ success: true });
                        setSubmitting(true);

                        dispatch(loginUser(values.userEmail, values.userPassword));

                    } catch (err) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login">Email Address</InputLabel>
                                    <OutlinedInput
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="userEmail"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter email address"
                                        fullWidth
                                        error={Boolean(touched.userEmail && errors.userEmail)}
                                    />
                                    {touched.userEmail && errors.userEmail && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.userEmail}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-login">Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(touched.userPassword && errors.userPassword)}
                                        id="-password-login"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.userPassword}
                                        name="userPassword"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="medium"
                                                >
                                                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}                                                    
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="Enter password"
                                    />
                                    {touched.userPassword && errors.userPassword && (
                                        <FormHelperText error id="standard-weight-helper-text-password-login">
                                            {errors.userPassword}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                           
                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    disableElevation
                                    disabled={Object.keys(errors).length !== 0}
                                    fullWidth
                                    loading={loading}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"                                                               
                                >
                                    Login
                                </LoadingButton>                                
                            </Grid>                            
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default LoginForm;