import * as React from 'react';
import { Typography, useTheme, Container, Box } from "@mui/material";
import { tokens } from "../../theme";

function Copyright() {
  return (
    <Typography variant="body1" color="text.secondary" sx={{ m: 'auto' }}>
      {'Copyright © '} 
      {new Date().getFullYear()}
      {' '} 
      <span style={{fontWeight: "bold"}}>MM Tracker</span>
    </Typography>
  );
}

export default function Footer(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            component="footer"
            sx={{
                py: 2,
                px: 2,
                width: '100%',
                position: "fixed",
                bottom: 0,
                backgroundColor: colors.primary[400],
                zIndex: "20"
            }}
        >
            <Container sx={{ display:'flex', alignItems: 'center' }}>
                <Copyright />
            </Container>
        </Box>
    );
}
