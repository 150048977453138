export const FETCH_TIMELOGS = "FETCH_TIMELOGS";

export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST"; 
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL"; 

export const UPDATE_LOGS_REQUEST = "UPDATE_LOGS_REQUEST"; 
export const UPDATE_LOGS_SUCCESS = "UPDATE_LOGS_SUCCESS";
export const UPDATE_LOGS_FAIL = "UPDATE_LOGS_FAIL"; 

export const FETCH_LOGS_REQUEST = "FETCH_LOGS_REQUEST";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS"; 

export const FETCH_TIMELOGS_REQUEST = "FETCH_TIMELOGS_REQUEST";
export const FETCH_TIMELOGS_SUCCESS = "FETCH_TIMELOGS_SUCCESS"; 
export const FETCH_LOGS_FAIL = "FETCH_LOGS_FAIL";
export const RESET_TIMELOGS = "RESET_TIMELOGS";