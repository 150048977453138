import { Box, TextField } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import * as yup from "yup";
import { useRef } from "react";
import { Formik, FieldArray, ErrorMessage } from 'formik';
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from '@mui/material/MenuItem';
import SelectPlaceFromMap from "../../components/SelectPlaceFromMap/SelectPlaceFromMap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../services/Actions/employeeActions"
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import { getFormatedDate, get24HourFormatTime, getStartEndDateTimeObjects, weekdays } from "../../Utils";
import MultiSelect from "../UI/MultiSelect/MultiSelect";
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useFormikContext } from 'formik';

export function ScrollToError() {
    const formik = useFormikContext();
    const submitting = formik?.isSubmitting;

    useEffect(() => {
        const el = document.querySelector('.Mui-error, [data-error]');
        (el?.parentElement ?? el)?.scrollIntoView();
    }, [submitting]);
    return null;
}

const JobForm = (props) => {

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [longitude, setLongitude] = useState(props.initialValues.longitude);
    const [latitude, setLatitude] = useState(props.initialValues.latitude);

    const { beforeToday } = DateRangePicker;

    const userState = useSelector((state) => state.userData);
    const dispatch = useDispatch();

    const employeeState = useSelector((state) => state.employee);
    const { employees } = employeeState;

    useEffect(() => {    
      if(userState && userState.user) {
        dispatch(fetchEmployees(userState.user.id));
      }
    }, [dispatch, userState]);

    const handleLongitude = (lng) => {
        setLongitude(lng);
    }

    const handleLatitude = (lat) => {
        setLatitude(lat);
    }

    const checkoutSchema = yup.object().shape({
        jobName: yup.string().required("Please enter the Job Name").max(50, "Job Name must be at most 50 characters"),
        jobDescription: yup.string().required("Please enter the Job Description").max(200, "Job Description must be at most 200 characters"),
        employeeId: yup.string().required('Please enter the Assigned To'),
        lineManager: yup.string().required("Please enter the line manager's name").max(50, "Name must be at most 50 characters"),
        lineMangerEmail: yup.string().required("Please enter the line manager's email").email('Must be a valid email').max(80, "Email must be at most 80 characters"),
        offDays: yup.array(),
        breakTime: yup.number().min(1, "Break Time must be greater than or equal to 1 min"),
        shiftDetails: yup
          .array()
          .of(
            yup.object().shape({
                shiftId: yup.string().nullable(),
                shiftStartEndDate: yup
                .array()
                .min(2, 'Please enter Start and end date')
                .required('Please enter Start and end date')
                .test('noOverlap', 'Shift is getting overlaped', function (value, cxt) {

                    if(this.parent.shiftStartEndDate.length === 0 || this.parent.shiftStartEndTime.length === 0) {
                        return true;    
                    }

                    const [newStartDate, newEndDate, newStartTime, newEndTime] = getStartEndDateTimeObjects(this.parent);
                    const shiftDetails = cxt.from.at(1).value.shiftDetails;

                    let index = -1;
                    for (let i = 0; i < shiftDetails.length; i++) {
                        if(shiftDetails[i].shiftStartEndDate[0] === this.parent.shiftStartEndDate[0] &&
                            shiftDetails[i].shiftStartEndDate[1] === this.parent.shiftStartEndDate[1] &&
                            shiftDetails[i].shiftStartEndTime[0] === this.parent.shiftStartEndTime[0] &&
                            shiftDetails[i].shiftStartEndTime[1] === this.parent.shiftStartEndTime[1]) {
                                index = i;
                                break;
                            }
                    }

                    for (let i = 0; i < shiftDetails.length; i++) {
                        if (i !== index && shiftDetails[i].shiftStartEndDate.length === 2) {
                        
                          const [existingStartDate, existingEndDate, existingStartTime, existingEndTime] = getStartEndDateTimeObjects(shiftDetails[i]);
                        
                          if (
                            (newStartDate.getTime() >= existingStartDate.getTime() && newStartDate.getTime() <= existingEndDate.getTime()) ||
                            (newEndDate.getTime() >= existingStartDate.getTime() && newEndDate.getTime() <= existingEndDate.getTime()) ||
                            (newStartDate.getTime() <= existingStartDate.getTime() && newEndDate.getTime() >= existingEndDate.getTime())
                          ) {

                            console.log("Hello");
                            console.log("newStartTime", newStartTime, "newEndTime", newEndTime, "existingStartTime", existingStartTime, "existingEndTime", existingEndTime);
                            if (
                                (newStartTime >= existingStartTime && newStartTime <= existingEndTime) ||
                                (newEndTime >= existingStartTime && newEndTime <= existingEndTime) || 
                                (newStartTime <= existingStartTime && newEndTime >= existingEndTime)
                              ) {
                                console.log("newStartTime", newStartTime, "newEndTime", newEndTime, "existingStartTime", existingStartTime, "existingEndTime", existingEndTime);
                                return false; // Overlapping shifts found    
                              }
                          }
                        }
                      }
                    return true;
                }),
                shiftStartEndTime: yup
                .array()
                .min(2, 'Start and end time is required')
                .required('Start and end time is required')
                .test('check-shift-time', 'Invalid time, please select future time range', function(value) {
                    const currentDate = new Date();
                    const selectedDate = new Date(this.parent.shiftStartEndDate[0]);
        
                    const selectedTime = new Date(value[0]);

                    if (selectedDate.toDateString() === currentDate.toDateString()) {
                        return currentDate.getTime() < selectedTime.getTime();
                    }
        
                    return true;
                })
            })
          ),
        thresholdTime: yup.number().min(1, "Threshold Time must be greater than or equal to 1 min"),
      });

    return(
        <Formik
            onSubmit={(values, { setSubmitting }) => {

                props.handleFormSubmit({
                    ...values,
                    thresholdTime: values.thresholdTime ? values.thresholdTime : 0,
                    breakTime: values.breakTime ? values.breakTime : 0,
                    longitude,
                    latitude,
                    "shiftDetails": values.shiftDetails.map((shiftDetails) => {
                        if(shiftDetails && shiftDetails.shiftId) {
                            return {
                                "id": shiftDetails.shiftId,
                                "startingDate": getFormatedDate(shiftDetails.shiftStartEndDate[0]),
                                "endingDate": getFormatedDate(shiftDetails.shiftStartEndDate[1]),
                                "shiftStartTime": get24HourFormatTime(shiftDetails.shiftStartEndTime[0]),
                                "shiftEndTime": get24HourFormatTime(shiftDetails.shiftStartEndTime[1])
                            }
                        } else {
                            return {
                                "startingDate": getFormatedDate(shiftDetails.shiftStartEndDate[0]),
                                "endingDate": getFormatedDate(shiftDetails.shiftStartEndDate[1]),
                                "shiftStartTime": get24HourFormatTime(shiftDetails.shiftStartEndTime[0]),
                                "shiftEndTime": get24HourFormatTime(shiftDetails.shiftStartEndTime[1])
                            }
                        }
                    })
                });
                
                if(props.btnText === "Add Job") {
                    // resetForm(props.initialValues);
                }

            }}
            initialValues={props.initialValues}
            validationSchema={checkoutSchema}            
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
            <form onSubmit={handleSubmit}>
                <ScrollToError/>
                <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                >
                    <TextField
                        fullWidth
                        variant="filled"               
                        type="text"
                        label="Job Name*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.jobName}
                        name="jobName"
                        error={!!touched.jobName && !!errors.jobName}
                        helperText={touched.jobName && errors.jobName}
                        maxLength={50}
                        sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
                    />
                    <TextField
                        fullWidth
                        variant="filled"               
                        type="text"
                        label="Job Description*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.jobDescription}
                        name="jobDescription"
                        maxLength={200}
                        error={!!touched.jobDescription && !!errors.jobDescription}
                        helperText={touched.jobDescription && errors.jobDescription}
                        sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
                    /> 
                    <TextField
                        fullWidth
                        variant="filled"               
                        select
                        label="Assigned to*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.employeeId}
                        name="employeeId"
                        error={!!touched.employeeId && !!errors.employeeId}
                        helperText={touched.employeeId && errors.employeeId}
                        sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
                    >
                        {
                            employees && employees.map((employee) => {
                                return <MenuItem value={employee.id}>{employee.user.name}</MenuItem>;
                            })
                        }
                    </TextField>
                    
                    <TextField
                        fullWidth
                        variant="filled"               
                        type="text"
                        label="Line Manager Name*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lineManager}
                        name="lineManager"
                        error={!!touched.lineManager && !!errors.lineManager}
                        helperText={touched.lineManager && errors.lineManager}
                        sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
                    />

                    <TextField
                        fullWidth
                        variant="filled"               
                        type="text"
                        label="Line Manager Email*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lineMangerEmail}
                        name="lineMangerEmail"
                        error={!!touched.lineMangerEmail && !!errors.lineMangerEmail}
                        helperText={touched.lineMangerEmail && errors.lineMangerEmail}
                        sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
                    />

                    <MultiSelect 
                        name="offDays"
                        value={values.offDays}
                        options={weekdays}
                        setValue={setFieldValue}
                        label="Off Days"
                        isError={touched.offDays && errors.offDays}
                    > 
                        <ErrorMessage name="offDays" component="div" className="error" />
                    </MultiSelect>
                    <TextField
                        fullWidth
                        variant="filled"               
                        type="number"
                        label="Threshold time in Minutes*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.thresholdTime}
                        InputLabelProps={{ shrink: true }}
                        name="thresholdTime"
                        error={!!touched.thresholdTime && !!errors.thresholdTime}
                        helperText={touched.thresholdTime && errors.thresholdTime}
                        sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
                    />                                                                                                       
                    <TextField
                        fullWidth
                        variant="filled"               
                        type="number"
                        label="Break time in Minutes*"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.breakTime}
                        InputLabelProps={{ shrink: true }}
                        name="breakTime"
                        error={!!touched.breakTime && !!errors.breakTime}
                        helperText={touched.breakTime && errors.breakTime}
                        sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
                    />                                                                                                       
                </Box>
                <Box 
                    component="fieldset"  
                    sx={{ mt:2 }}                       
                >
                            <legend>Shift Details</legend> 
                            <FieldArray 
                                name="shiftDetails" 
                                sx={{
                                    my: "30px",
                                    padding: "20px",
                                }}
                            >
                                {({ push, remove }) => (
                                    <>
                                        {values.shiftDetails.map((field, index) => (
                                            <Box sx={{ flexGrow: 1 }} key={index}>   
                                            <Grid container>
                                        
                                                <Grid item xs={12} md={12}>
                                                    <Box display="flex" sx={{ gap: "10px" }}>
                                                        <label htmlFor={`shiftDetails.${index}.shiftStartEndDate`}>
                                                            <span>Shift{index + 1}: </span>
                                                        </label>
                                                        <input
                                                            name="shiftDetails.${index}.shiftId"
                                                            value={values.shiftDetails[index].shiftId}
                                                            type="hidden"
                                                        />
                                                        <FormControl 
                                                            error={
                                                                touched.shiftDetails && touched.shiftDetails[index]?.shiftStartEndDate && 
                                                                errors.shiftDetails && errors.shiftDetails[index]?.shiftStartEndDate
                                                            }>
                                                            <DateRangePicker
                                                                format="yyyy-MM-dd"
                                                                placeholder="Select CheckIn Date ~ Checkout Date Range"
                                                                name={`shiftDetails.${index}.shiftStartEndDate`}
                                                                value={values.shiftDetails[index].shiftStartEndDate}
                                                                shouldDisableDate={beforeToday()}
                                                                onChange={(value) => {
                                                                    setFieldValue(
                                                                        `shiftDetails.${index}.shiftStartEndDate`,
                                                                        value
                                                                    );                                                             
                                                                }}
                                                            />
                                                            <FormHelperText>
                                                                <ErrorMessage name={`shiftDetails.${index}.shiftStartEndDate`} component="div" className="error" />
                                                            </FormHelperText>
                                                        </FormControl>
                                                        <FormControl 
                                                            error={
                                                                touched.shiftDetails && touched.shiftDetails[index]?.shiftStartEndTime && 
                                                                errors.shiftDetails && errors.shiftDetails[index]?.shiftStartEndTime
                                                            }>
                                                            <DateRangePicker
                                                                placeholder="Select CheckIn Time ~ Checkout Time Range"
                                                                format="HH:mm"
                                                                name={`shiftDetails.${index}.shiftStartEndTime`}
                                                                value={values.shiftDetails[index].shiftStartEndTime}
                                                                onChange={(value) => {
                                                                    setFieldValue(
                                                                        `shiftDetails.${index}.shiftStartEndTime`,
                                                                        value
                                                                    );                                                
                                                                }}
                                                            />
                                                            <FormHelperText>
                                                                <ErrorMessage name={`shiftDetails.${index}.shiftStartEndTime`} component="div" className="error" />
                                                            </FormHelperText>
                                                        </FormControl>

                                                        {values.shiftDetails.length > 1 && (
                                                            <IconButton
                                                                sx={{ visibility: 'inline' }}
                                                                color="error"
                                                                component="label"
                                                                type="button"
                                                                onClick={() => {
                                                                    remove(index);
                                                                }}
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                                    <Box display="flex" justifyContent="end" mt="20px">
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            component="label"
                                            type="button"
                                            onClick={() => {
                                                push({ "shiftStartEndDate": [], 
                                                    "shiftStartEndTime": []
                                                    }); // Push an empty object to the shiftDetails array
                                                }}
                                        >
                                        Add Shift
                                        </Button>
                                    </Box>
                                    </>
                                )}
                            </FieldArray>    
                </Box>                         
                <Box sx={{ height: "20px" }} />
                <SelectPlaceFromMap lng={longitude} lat={latitude} setLng={handleLongitude} setLat={handleLatitude}/>
                <Box display="flex" justifyContent="end" mt="20px" mb="45px">
                    <LoadingButton type="submit" loading={props.loading} color="secondary" variant="contained">
                        {props.btnText}
                    </LoadingButton>
                </Box>
            </form>
            )}
        </Formik>
    );
}

export default JobForm;
