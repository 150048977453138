import React, { useState, useEffect } from 'react';
import { Box, useTheme, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import DeleteConfirmation from '../UI/Prompt/DeleteConfirmation';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET_JOB } from '../../services/Constants/jobConstants';
import { fetchJobs, deleteJob } from '../../services/Actions/jobActions';
import { CustomNoDataAvailableOverlay } from '../UI/CustomNoDataAvailableOverlay/CustomNoDataAvailableOverlay';
import { TableLoading } from '../UI/TableLoading/TableLoading';

const JobTable = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [recordId, setRecordId] = useState(0);
  
  const handleClose = () => {
    setDeleteOpen(false);
  };

  const dispatch = useDispatch();

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });  

  const jobState = useSelector((state) => state.jobData);
  const { jobs, jobDeleted, loading, error } = jobState;

  const userState = useSelector((state) => state.userData);

  useEffect(() => {    
    if(userState && userState.user) {
      dispatch(fetchJobs(userState.user.id));
    }
  }, [dispatch, jobDeleted, userState, error]);

  useEffect(() => {
    if(jobDeleted) {
      toast.success("Job Deleted Successfully");
      dispatch({ type: RESET_JOB})
    }
  }, [jobDeleted, dispatch]);

  useEffect(() => {
      if(error) {
          toast.error(error.message);
          dispatch({ type: RESET_JOB})
      }
  }, [error, dispatch]);

  const handleDelete = () => {
    dispatch(deleteJob(recordId));
    handleClose();
  };

  const columns = [
    {
      field: "jobName",
      headerName: "Job Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "jobDescription",
      headerName: "Job Description",
      flex: 1
    },
    {
      field: "longitude",
      headerName: "Longitude",
      flex: 1,
    },
    {
      field: "latitude",
      headerName: "Latitude",
      flex: 1,
    },
    {
      field: "thresholdTime",
      headerName: "Threshold Time",
      flex: 1,
    },
    {
      field: "breakTime",
      headerName: "Break Time",
      flex: 1,
    },    
    {
      field: "lineManager",
      headerName: "Line Manager",
      flex: 1,
    },    
    {
      field: "offDays",
      headerName: "Off Days",
      flex: 1,
    },    
    {
      field: "name",
      headerName: "Assigned To",
      flex: 1,
      valueGetter: (params) => params.row.employee.user.name
    },

  ];

  if(!props.noActions) {
    columns.push(
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 150,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (<>
              <IconButton
                aria-label="edit"
                onClick={() => {
                  navigate(`/job/update/${params.row.id}`)
                  // handle edit action here
                }}
                sx={{
                  color: {
                    light: 'black',
                    dark: 'white',
                  },
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDeleteOpen(true);
                  setRecordId(params.row.id);
                }}
                color="error"
              >
                <Delete />
              </IconButton>
            </>
          );
        }
      }
    );
  }

  return (
    <Box
      height="65vh"
      sx={{
        "& .name-column--cell": {
          color: colors.blueAccent[300],
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      { props.noActions ?
        <DataGrid 
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rows={[...jobs].reverse()} 
          columns={columns} /> :
        <DataGrid 
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rows={[...jobs].reverse()}  
          columns={columns} 
          loading={loading}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: CustomNoDataAvailableOverlay,
            loadingOverlay: TableLoading
          }}
        /> }
        <DeleteConfirmation open={deleteOpen} handleClose={handleClose} handleDelete={handleDelete} recordId={recordId} msg="Are you sure you want to delete this record? It will also delete the timelog if any."/>
    </Box>
  );
};

export default JobTable;
